@font-face {
  font-family: Cambria;
  font-style: normal;
  font-weight: 400;
  src: local("Cambria Regular"), url("/dist/fonts/cambria/cambria.ttf") format("truetype");
}

@font-face {
  font-family: Cambria;
  font-style: normal;
  font-weight: 700;
  src: local("Cambria Bold"), url("/dist/fonts/cambria/cambriab.ttf") format("truetype");
}

@font-face {
  font-family: Cambria;
  font-style: italic;
  font-weight: 400;
  src: local("Cambria Italic"), url("/dist/fonts/cambria/cambriai.ttf") format("truetype");
}

@font-face {
  font-family: Cambria;
  font-style: italic;
  font-weight: 700;
  src: local("Cambria Bold Italic"), url("/dist/fonts/cambria/cambriaz.ttf") format("truetype");
}
