@import "./additional_icons";
@import "./Cambria";
@import "./Goudy_Old_Style";
@import "./icon_font";
@import "./Montserrat";

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

.widget-white {
  background-color: #f9f9f9;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (min-width: 992px) {
  html {
    overflow-x: auto;
  }
}

body {
  font-size: 16px;
  line-height: 1.4;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

main {
  overflow-x: hidden;
}

@media (min-width: 992px) {
  main {
    overflow-x: auto;
  }
}

::-moz-selection {
  background: #6fcbed;
  text-shadow: none;
}

::selection {
  background: #6fcbed;
  text-shadow: none;
}

.upper-elements {
  position: relative;
}

.page-content {
  min-height: 460px;
  padding: 2em 0 5em;
}

.page-content.darker {
  background: #f9f9f9;
}

.page-content.shorter {
  padding: 0;
}

iframe {
  width: 100% !important;
}

b,
strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

p {
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
  color: #21607a;
}

.size-alpha,
h1 {
  font-size: 1.9em;
}

.size-bravo,
h2 {
  font-size: 1.5em;
}

.size-charlie,
h3 {
  font-size: 1.5em;
}

.size-delta,
h4 {
  font-size: 1.25em;
}

.size-echo,
h5 {
  font-size: 1em;
  font-weight: 400;
}

.size-foxtrot,
h6 {
  font-size: .875em;
}

.size-golf {
  font-size: .75em;
}

.size-hotel {
  font-size: .625em;
}

h1,
h2,
h3,
h4,
h5,
h6,
hr,
p {
  margin-bottom: 1em;
}

hr {
  color: #eee;
  border: none;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin-bottom: 2em;
}

hr.dashed {
  border-bottom-style: dashed;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

.icon,
[class*=" icon-"],
[class^=icon-] {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contact-address:before,
.icon-faux-pin {
  display: inline-block;
  zoom: 1;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-arrow-left {
  display: inline-block;
  zoom: 1;
  -webkit-transform: rotateZ(180deg) translateY(-1px);
  transform: rotateZ(180deg) translateY(-1px);
}

.icon-arrow-right-double {
  display: inline-block;
  zoom: 1;
  -webkit-transform: rotateZ(180deg) translateY(-1px);
  transform: rotateZ(180deg) translateY(-1px);
}

.contact-email:before,
.icon-mail:before,
.share-mail:before {
  content: "\e002";
}

.contact-tel:before,
.icon-ribbon:before {
  content: "\e009";
}

.icon-clock:before,
.news-meta-date:before {
  content: "\e014";
}

.icon-stack:before {
  content: "\e032";
}

.icon-paper:before,
.widget-title.has-icon:before {
  content: "\e034";
}

.icon-search:before,
.input-icon-search .js-btn-append:after,
.nav-search:before,
.smart-search-input .js-btn-append:after {
  content: "\e036";
}

.icon-arrow-left-double:before,
.icon-arrow-right-double:before,
.icon-rewind:before {
  content: "\e051";
}

.contact-address:before,
.icon-drop:before,
.icon-faux-pin {
  content: "\e063";
}

.block--location .block-head:after,
.icon-location:before {
  content: "\e070";
}

.block--person .block-head:after,
.contact-person:before,
.icon-head:before,
.nav-login:before,
.news-meta-posted-by:before,
.widget-bloggers li>a:before {
  content: "\e074";
}

.icon-speech-bubble:before,
.news-meta-comments:before {
  content: "\e076";
}

.icon-share:before {
  content: "\e081";
}

.icon-tag:before,
.tags-meta:before {
  content: "\e085";
}

.breadcrumb a:after,
.btn-arrow-down:after,
.btn-arrow:after,
.btn-share:after,
.icon-arrow-left:before,
.icon-arrow-right:before {
  content: "\e095";
}

.btn-arrow-down:after,
.icon-arrow-down2:before {
  content: "\e097";
}

.icon-cross:before,
.tag-icon:after {
  content: "\e117";
}

.icon-arrow-down:before,
.select:before {
  content: "\e604";
}

.icon-twitter:before,
.share-twitter:before,
.social-link-twitter:before {
  content: "\e600";
}

.icon-facebook:before,
.share-facebook:before,
.social-link-facebook:before {
  content: "\e601";
}

.icon-googleplus:before,
.share-googleplus:before,
.social-link-googleplus:before {
  content: "\e602";
}

.icon-linkedin:before,
.share-linkedin:before,
.social-link-linkedin:before {
  content: "\e603";
}

.icon-instagram,
.share-instagram,
.social-link-instagram {
  background-image: url(/dist/img/instagram.png);
  background-size: 24px;
}

.muted {
  opacity: .4;
  filter: "alpha(opacity=.4)";
  zoom: 1;
}

.padBtm10 {
  padding-bottom: 10px;
}

.noBold,
.noBold a {
  font-weight: 400 !important;
}

.RTL .page-content .container>h1 {
  padding-right: 390px;
}

@media screen and (max-width: 1200px) {
  .RTL .page-content .container>h1 {
    padding-right: 325px;
  }
}

@media screen and (max-width: 992px) {
  .RTL .page-content .container>h1 {
    padding-right: 0;
  }
}

.EditorWidget:after,
.EditorWidget:before,
.GroupWidget:after,
.GroupWidget:before,
.WebPartBorderActive:after,
.WebPartBorderActive:before,
.WebPartZoneBorder:after,
.WebPartZoneBorder:before,
.WebPartZoneBorderActive:after,
.WebPartZoneBorderActive:before,
.block-head:after,
.block-head:before,
.block:after,
.block:before,
.breadcrumb:after,
.breadcrumb:before,
.container:after,
.container:before,
.generic-search-result .panel:after,
.generic-search-result .panel:before,
.media:after,
.media:before,
.nav-primary-buttons:after,
.nav-primary-buttons:before,
.news-meta:after,
.news-meta:before,
.owl-buttons:after,
.owl-buttons:before,
.related-people-list:after,
.related-people-list:before,
.row:after,
.row:before,
.s-result-inner:after,
.s-result-inner:before,
.site-branding:after,
.site-branding:before,
.split li:after,
.split li:before,
.split-equal li:after,
.split-equal li:before,
.split:after,
.split:before,
.widget-contact:after,
.widget-contact:before,
.widget:after,
.widget:before {
  content: " ";
  display: block;
  clear: both;
}

.faux-clear {
  clear: both;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {

  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 992px) {

  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .right-col {
    float: right;
  }
}

@media (min-width: 1200px) {

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }
}

.col-centered {
  margin: 0 auto;
  float: none !important;
}

.col-no-pad {
  padding: 0;
}

.col-pad-v {
  padding: 0 8px;
}

.selector-bar-pad {
  padding: 0;
}

@media (min-width: 768px) {
  .selector-bar-pad {
    padding: 0 8px;
  }
}

.input--text,
.select,
input[type=text],
input[type=file],
td.EditingFormValueCell select,
td.form-table-value-cell select,
textarea {
  font-size: .9em;
  font-family: inherit;
  font-weight: 400;
  background-color: #edebeb;
  border: 0;
  color: #4d4d4d;
  border-bottom: 2px solid #00928f;
  -webkit-transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
}

.input--text:focus,
.select:focus,
input[type=text]:focus,
input[type=file]:focus,
td.EditingFormValueCell select:focus,
td.form-table-value-cell select:focus,
textarea:focus {
  outline: 2px solid #00928f;
  background-color: #f9f9f9;
}

.input--text,
input[type=text],
input[type=file],
textarea {
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
}

.form-light .input--text,
.form-light .select,
.form-light input[type=text],
.form-light input[type=file],
.form-light td.EditingFormValueCell select,
.form-light td.form-table-value-cell select,
.form-light textarea,
td.EditingFormValueCell .form-light select,
td.form-table-value-cell .form-light select {
  background-color: #f4f4f4;
  border: 0;
}

.form-light .input--text:focus,
.form-light .select:focus,
.form-light input[type=text]:focus,
.form-light input[type=file]:focus,
.form-light td.EditingFormValueCell select:focus,
.form-light td.form-table-value-cell select:focus,
.form-light textarea:focus,
td.EditingFormValueCell .form-light select:focus,
td.form-table-value-cell .form-light select:focus {
  background-color: #fff;
  outline-color: #595959;
  outline-color: rgba(0, 0, 0, 0.62);
}

.input--text,
input[type=text],
input[type=file] {
  height: 40px;
}

textarea {
  padding-top: 14px;
  padding-top: 14px;
}

.label,
label,
span.EditingFormLabel {
  display: block;
  color: #21607a;
  font-size: 1.1em;
  margin-bottom: .2em;
}

.label em,
label em,
span.EditingFormLabel em {
  color: #a30046;
  margin: 0 4px;
  font-style: normal;
  font-size: .8em;
}

button,
input,
label,
select,
textarea {
  cursor: pointer;
}

fieldset {
  margin-bottom: 2em;
}

.lt-ie9 .input--text {
  line-height: 40px;
}

::-webkit-input-placeholder {
  color: #8e8e8e;
  font-weight: 300;
}

:-moz-placeholder {
  color: #8e8e8e;
  font-weight: 300;
}

::-moz-placeholder {
  color: #8e8e8e;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #8e8e8e;
  font-weight: 300;
}

.form-group {
  margin-bottom: 34px;
}

.input-icon {
  position: relative;
}

.input-icon:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: .6em;
  color: #bbb;
  margin-top: -13px;
  font-size: 25px;
  pointer-events: none;
}

.input-icon input {
  padding-right: 2.9em;
}

.form-control-error {
  color: #c33d3d;
}

.input-icon-search,
.smart-search-input {
  position: relative;
  margin-bottom: 2em;
  text-align: left;
}

.input-icon-search input,
.smart-search-input input {
  padding-right: 2.6em;
}

.input-icon-search.input-icon:after,
.smart-search-input.input-icon:after {
  display: none;
}

.input-icon-search .js-btn-append,
.smart-search-input .js-btn-append {
  position: absolute;
  right: .4em;
  top: 50%;
  margin-top: -18px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.input-icon-search .js-btn-append:after,
.smart-search-input .js-btn-append:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 5px;
  left: 6px;
  font-size: 25px;
  color: #bbb;
}

.input-icon-search .js-btn-append:hover:after,
.smart-search-input .js-btn-append:hover:after {
  color: #888;
}

.placeholder {
  color: #999 !important;
  font-size: 0.9em !important;
}

.checkbox-radio-group {
  margin-bottom: 34px;
}

.checkbox-radio-group label {
  max-width: 95%;
  vertical-align: middle;
}

.checkbox-radio {
  display: inline-block;
  margin-right: 1em;
}

.checkbox-radio .EditingFormControlNestedControl,
.checkbox-radio input,
.checkbox-radio label {
  display: inline-block;
}

.checkbox-radio input {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.checkbox-radio label {
  font-size: .9em;
}

.address-group input {
  margin-bottom: 11px;
}

.address-group .input:last-of-type {
  margin-bottom: -2px;
}

.ErrorLabel {
  color: #a30046;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 1em;
}

.form-group-validation,
.form-group-validation span {
  color: #a30046;
  font-weight: 700;
}

input[type=button],
input[type=submit],
input[type=text],
input[type=file],
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

.ErrorLabel {
  color: #a30046 !important;
}

.Error {
  border: 1px solid #a30046;
}

.captcha .Error {
  padding: 4px;
}

.form-group-select .select {
  margin-bottom: .1em;
}

.mstphFrm3Col {
  width: 30%;
  float: left;
}

@media (max-width: 992px) {
  .mstphFrm3Col {
    width: 98%;
    float: none;
  }
}

.mstphFrmTxtArea textarea {
  height: 10em;
}

.checkbox-list-vertical label {
  display: inline-block;
  font-size: .9em;
  padding-bottom: .3em;
}

.mstphFrm2Col {
  width: 46%;
  float: left;
}

@media (max-width: 992px) {
  .mstphFrm2Col {
    width: 98%;
    float: none;
  }
}

.mstphFrmCol-rightMarg {
  margin-right: 2em;
}

.mstphFrmTxt {
  margin-bottom: 1em;
}

.mstphFrmTxtArea-Large textarea {
  height: 30em;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  color: #5c5c5c;
}

a:hover h1,
a:hover h2,
a:hover h3,
a:hover h4,
a:hover h5 {
  color: #4d8095;
}

a:active,
a:hover {
  outline: 0;
}

.InlineAbuseLink,
.link-inline {
  font-size: .9em;
}

.search-link {
  display: block;
  margin-bottom: .8em;
  font-weight: 400;
  color: #00928f;
}

.landing-sector-description a,
.location-details a,
.news-post a {
  color: #50c8e8;
}

dd,
ol,
ul {
  margin-left: 24px;
}

nav ol,
nav ul {
  margin-left: 0;
}

nav li {
  list-style: none;
}

dl,
ol,
ul {
  margin-bottom: 2em;
}

ol {
  counter-reset: my-counter;
}

ol>li {
  position: relative;
  margin-bottom: 1.1em;
}

ol>li:before {
  content: counter(my-counter);
  counter-increment: my-counter;
  position: absolute;
  left: -1.1em;
  top: 50%;
  margin-top: -19px;
  font-size: 1.6em;
  color: #939393;
}

.list--plain {
  margin-left: 0;
}

.related-people-list {
  margin-left: 0;
}

.sitemap {
  margin-left: 0;
}

.input--text,
.select,
input[type=text],
input[type=file],
td.EditingFormValueCell select,
td.form-table-value-cell select,
textarea {
  font-size: .9em;
  font-family: inherit;
  font-weight: 400;
  background-color: #edebeb;
  border: 0;
  color: #4d4d4d;
  border-bottom: 2px solid #00928f;
  -webkit-transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
}

.input--text:focus,
.select:focus,
input[type=text]:focus,
input[type=file]:focus,
td.EditingFormValueCell select:focus,
td.form-table-value-cell select:focus,
textarea:focus {
  outline: 2px solid #00928f;
  background-color: #f9f9f9;
}

.input--text,
input[type=text],
input[type=file],
textarea {
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
}

.form-light .input--text,
.form-light .select,
.form-light input[type=text],
.form-light input[type=file],
.form-light td.EditingFormValueCell select,
.form-light td.form-table-value-cell select,
.form-light textarea,
td.EditingFormValueCell .form-light select,
td.form-table-value-cell .form-light select {
  background-color: #f4f4f4;
  border: 0;
}

.form-light .input--text:focus,
.form-light .select:focus,
.form-light input[type=text]:focus,
.form-light input[type=file]:focus,
.form-light td.EditingFormValueCell select:focus,
.form-light td.form-table-value-cell select:focus,
.form-light textarea:focus,
td.EditingFormValueCell .form-light select:focus,
td.form-table-value-cell .form-light select:focus {
  background-color: #fff;
  outline-color: #595959;
  outline-color: rgba(0, 0, 0, 0.62);
}

.input--text,
input[type=text],
input[type=file] {
  height: 40px;
}

textarea {
  padding-top: 14px;
  padding-top: 14px;
}

.label,
label,
span.EditingFormLabel {
  display: block;
  color: #21607a;
  font-size: 1.1em;
  margin-bottom: .2em;
}

.label em,
label em,
span.EditingFormLabel em {
  color: #a30046;
  margin: 0 4px;
  font-style: normal;
  font-size: .8em;
}

button,
input,
label,
select,
textarea {
  cursor: pointer;
}

fieldset {
  margin-bottom: 2em;
}

.lt-ie9 .input--text {
  line-height: 40px;
}

::-webkit-input-placeholder {
  color: #8e8e8e;
  font-weight: 300;
}

:-moz-placeholder {
  color: #8e8e8e;
  font-weight: 300;
}

::-moz-placeholder {
  color: #8e8e8e;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #8e8e8e;
  font-weight: 300;
}

.form-group {
  margin-bottom: 34px;
}

.input-icon {
  position: relative;
}

.input-icon:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: .6em;
  color: #bbb;
  margin-top: -13px;
  font-size: 25px;
  pointer-events: none;
}

.input-icon input {
  padding-right: 2.9em;
}

.form-control-error {
  color: #c33d3d;
}

.input-icon-search,
.smart-search-input {
  position: relative;
  margin-bottom: 2em;
  text-align: left;
}

.input-icon-search input,
.smart-search-input input {
  padding-right: 2.6em;
}

.input-icon-search.input-icon:after,
.smart-search-input.input-icon:after {
  display: none;
}

.input-icon-search .js-btn-append,
.smart-search-input .js-btn-append {
  position: absolute;
  right: .4em;
  top: 50%;
  margin-top: -18px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.input-icon-search .js-btn-append:after,
.smart-search-input .js-btn-append:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 5px;
  left: 6px;
  font-size: 25px;
  color: #bbb;
}

.input-icon-search .js-btn-append:hover:after,
.smart-search-input .js-btn-append:hover:after {
  color: #888;
}

.placeholder {
  color: #999 !important;
  font-size: 0.9em !important;
}

.checkbox-radio-group {
  margin-bottom: 34px;
}

.checkbox-radio-group label {
  max-width: 95%;
  vertical-align: middle;
}

.checkbox-radio {
  display: inline-block;
  margin-right: 1em;
}

.checkbox-radio .EditingFormControlNestedControl,
.checkbox-radio input,
.checkbox-radio label {
  display: inline-block;
}

.checkbox-radio input {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.checkbox-radio label {
  font-size: .9em;
}

.address-group input {
  margin-bottom: 11px;
}

.address-group .input:last-of-type {
  margin-bottom: -2px;
}

.ErrorLabel {
  color: #a30046;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 1em;
}

.form-group-validation,
.form-group-validation span {
  color: #a30046;
  font-weight: 700;
}

input[type=button],
input[type=submit],
input[type=text],
input[type=file],
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

.ErrorLabel {
  color: #a30046 !important;
}

.Error {
  border: 1px solid #a30046;
}

.captcha .Error {
  padding: 4px;
}

.form-group-select .select {
  margin-bottom: .1em;
}

.mstphFrm3Col {
  width: 30%;
  float: left;
}

@media (max-width: 992px) {
  .mstphFrm3Col {
    width: 98%;
    float: none;
  }
}

.mstphFrmTxtArea textarea {
  height: 10em;
}

.checkbox-list-vertical label {
  display: inline-block;
  font-size: .9em;
  padding-bottom: .3em;
}

.mstphFrm2Col {
  width: 46%;
  float: left;
}

@media (max-width: 992px) {
  .mstphFrm2Col {
    width: 98%;
    float: none;
  }
}

.mstphFrmCol-rightMarg {
  margin-right: 2em;
}

.mstphFrmTxt {
  margin-bottom: 1em;
}

.mstphFrmTxtArea-Large textarea {
  height: 30em;
}

select::-moz-focus-inner {
  border: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

td.EditingFormValueCell select,
td.form-table-value-cell select {
  width: 100%;
  height: 40px;
  padding: 0 1.9em 0 .8em;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: .9em;
  font-family: inherit;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  border-bottom: 2px solid #00928f;
  background-color: #edebeb;
}

td.EditingFormValueCell select:focus,
td.form-table-value-cell select:focus {
  background-color: #f9f9f9;
  outline: 2px solid #00928f;
}

td.EditingFormValueCell select::-ms-expand,
td.form-table-value-cell select::-ms-expand {
  display: none;
}

.select {
  position: relative;
  overflow: hidden;
  margin-bottom: 34px;
}

.select select {
  width: 100%;
  height: 40px;
  padding: 0 1.9em 0 .8em;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: .9em;
  font-family: inherit;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
}

.select select:focus {
  background-color: #f9f9f9;
  outline: 2px solid #00928f;
}

.select select::-ms-expand {
  display: none;
}

.select:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 13px;
  pointer-events: none;
  margin-top: -8px;
  color: #bbb;
  font-size: 1.1em;
}

.form-light select:focus {
  outline-color: #595959;
  outline-color: rgba(0, 0, 0, 0.62);
}

.lt-ie9 .select select:before {
  border-top-color: #fff !important;
}

.form-group-select {
  margin-bottom: 0;
}

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
  overflow-x: auto;
  min-height: .01%;
}

td,
th {
  text-align: left;
  padding: 0;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

table>tbody>tr>td,
table>tbody>tr>th,
table>tfoot>tr>td,
table>tfoot>tr>th,
table>thead>tr>td,
table>thead>tr>th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}

table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

table>caption+thead>tr:first-child>td,
table>caption+thead>tr:first-child>th,
table>colgroup+thead>tr:first-child>td,
table>colgroup+thead>tr:first-child>th,
table>thead:first-child>tr:first-child>td,
table>thead:first-child>tr:first-child>th {
  border-top: 0;
}

table>tbody+tbody {
  border-top: 2px solid #ddd;
}

table table {
  background-color: #fff;
}

table>thead>tr>td,
table>thead>tr>th {
  border-bottom-width: 2px;
}

table>tbody>tr:nth-of-type(odd):not(.cms-bootstraptr) {
  background-color: #f9f9f9;
}

.CaptchaTable td {
  padding-left: 0;
}

.CaptchaTable td:nth-child(2) {
  vertical-align: middle;
}

button,
input[type=button],
input[type=submit] {
  border: 0;
  font: inherit;
  -webkit-font-smoothing: antialiased;
  text-align: left;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.btn,
.btn-arrow,
.btn-arrow-down,
.btn-share {
  display: inline-block;
  zoom: 1;
  position: relative;
  padding: .6em .8em;
  margin-bottom: 2em;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  background-color: #00928f;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  z-index: 10;
  -webkit-transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
}

.btn-arrow-down:hover,
.btn-arrow:hover,
.btn-share:hover,
.btn:hover {
  background-color: #33a8a5;
  color: #fff;
}

.btn-arrow-down:focus,
.btn-arrow:focus,
.btn-share:focus,
.btn:focus {
  outline: 2px solid #80c9c7;
}

.btn-arrow-down:active,
.btn-arrow:active,
.btn-share:active,
.btn:active {
  outline: 0;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

@media (min-width: 480px) {

  .btn,
  .btn-arrow,
  .btn-arrow-down,
  .btn-share {
    width: auto;
  }
}

.btn-arrow,
.btn-arrow-down,
.btn-share {
  text-align: left;
  padding-right: 2.3em;
  color: #fff;
}

.btn-arrow-down:after,
.btn-arrow:after,
.btn-share:after {
  -webkit-transition: all 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: all 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}

.btn-arrow:hover:after {
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
}

@-moz-document url-prefix() {
  .btn-arrow:hover:after {
    transform: none;
    right: 13px;
  }
}

.btn-arrow-down:after {
  margin-top: -10px;
}

.btn-arrow-down.btn-large {
  padding-top: .4em;
  padding-bottom: .4em;
  padding-left: .6em;
}

.btn-secondary {
  background-color: #00928f;
}

.btn-secondary:hover {
  background-color: #007572;
}

.btn-secondary:focus {
  outline: 2px solid #80c9c7;
}

.btn-secondary:active {
  outline: 0;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.btn-large {
  font-size: 1.3em;
  font-weight: 300;
}

.btn-small {
  padding-top: .2em;
  padding-bottom: .2em;
  padding-left: .5em;
}

.btn-is-type-input input {
  margin: 0;
  padding: 0;
}

.btn-is-type-input input:hover input {
  background-color: #33a8a5;
}

.btn-is-type-input input:focus {
  outline: 0;
}

.btn-is-type-input:focus {
  outline: 2px solid #80c9c7;
}

.btn-share {
  position: relative;
  z-index: 1;
}

.btn-share:after {
  content: "\e081";
}

.js-directory-submit input {
  display: none;
}

.btn-altCol {
  background-color: #26a;
}

.quote-body,
blockquote {
  position: relative;
  padding: .8em 1.3em;
  margin-bottom: 1.6em;
  line-height: 1.6;
  background-color: #c3d0e4;
  color: #21607a;
}

.quote-body :last-of-type,
blockquote :last-of-type {
  margin-bottom: 0;
}

.quote-citation {
  color: #21607a;
  position: relative;
  left: 8px;
  display: inline-block;
  margin-bottom: 26px;
}

blockquote {
  padding: 1em;
  margin-bottom: 1.5em;
}

blockquote p {
  margin-bottom: 0;
}

blockquote:after {
  content: "";
  height: 0;
  width: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid #c3d0e4;
  position: absolute;
  bottom: -13px;
  left: 28px;
}

@media (min-width: 480px) {
  blockquote {
    font-size: 1.1em;
  }
}

img {
  max-width: 100%;
  font-style: italic;
}

.clipped-img {
  height: 130px;
  width: 112px;
  overflow: hidden;
  position: relative;
}

.clipped-img img {
  position: absolute;
  top: 0;
}

nav {
  list-style: none;
}

nav ul {
  margin-bottom: 0;
}

nav a,
nav li {
  display: inline-block;
  zoom: 1;
}

.msMultiLevelMenu .nav-tertiary ul li ul {
  margin-bottom: 0;
  border-left: 2px solid #00aeef !important;
}

.msMultiLevelMenu .nav-tertiary ul li ul li a {
  margin-left: 20px;
  border-left: 2px dotted #6fcbed !important;
}

.pagination a,
.pagination li {
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}

.pagination {
  margin-left: 0;
  margin-bottom: 2em;
  list-style: none;
}

.pagination li {
  display: block;
  float: left;
  background-color: #00aeef;
  border-right: 1px solid #46cbfd;
  border-right: 1px solid rgba(255, 255, 255, 0.33);
}

.pagination li.paignation-item:last-child {
  border-right: 0;
}

.pagination li:hover {
  background-color: #33bef2;
}

.pagination li.is-current {
  background-color: #edebeb;
  color: #333;
  cursor: default;
  padding-top: 9px;
}

.pagination li.is-current:hover {
  background-color: #edebeb;
}

.pagination a {
  display: block;
  padding-top: 9px;
}

.media-img {
  float: left;
  margin-right: 1em;
}

.media-img>img {
  display: block;
}

.MediaGallery .MediaGalleryFolderTree {
  display: none;
}

.MediaGalleryContent .MediaLibrarySort {
  display: none;
}

.MediaGalleryContent .mediaItem {
  display: inline-block;
  padding: 10px;
  background-color: #eee;
  min-width: 200px;
  min-height: 200px;
}

.MediaGalleryContent .mediaItem table {
  margin-bottom: 0;
}

.MediaGalleryContent .Pager {
  margin-top: 20px;
}

.MediaGalleryContent .mediaItem .mediaLibraryDescription,
.mediaItemBottom {
  display: none;
}

.MediaGalleryContent .photoSeparator {
  display: none;
}

.island {
  background-color: #f4f4f4;
  padding: 2em;
  margin-bottom: 2em;
}

.split {
  text-align: left;
  list-style: none;
  margin-left: 0;
}

.split * {
  margin-bottom: 0;
}

.split-title {
  text-align: left;
  float: left;
  clear: left;
}

@media (max-width: 767px) {
  .split-equal .split-title {
    float: none;
  }
}

.split-equal .split-data {
  display: block;
}

@media (min-width: 767px) {
  .split-equal .split-data {
    float: left;
  }
}

.strip {
  background-color: #edebeb;
  margin-bottom: 2em;
  padding: 1em;
}

.strip * {
  margin-bottom: 0;
}

.strip .result-count {
  font-size: .9em;
}

@media (min-width: 992px) {
  .strip .result-count {
    text-align: right;
  }
}

.strip--light {
  background-color: #f7f7f7;
}

.breadcrumb {
  display: none;
  list-style: none;
  margin-bottom: 0;
}

.breadcrumb li {
  display: inline-block;
  zoom: 1;
  vertical-align: baseline;
}

.breadcrumb a,
.breadcrumb span {
  position: relative;
  margin-right: 24px;
  font-size: .9em;
}

.breadcrumb a {
  color: #00aeef;
}

.breadcrumb a:hover {
  color: #008bbf;
}

.breadcrumb a:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 4px;
  right: -18px;
  font-size: .8em;
  color: #b1b1b1;
}

.breadcrumb span {
  color: #21607a;
}

.breadcrumb br {
  display: none;
}

@media (min-width: 992px) {
  .breadcrumb {
    display: block;
  }
}

.tag,
.tag-icon {
  display: inline-block;
  zoom: 1;
  position: relative;
  padding: .1em .5em;
  margin-right: .5em;
  font-size: .9em;
  color: #fff !important;
  background-color: #00aeef;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  -webkit-transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: background-color 0.2s cubic-bezier(0.14, 0, 0.79, 1);
}

.tag-icon:hover,
.tag:hover {
  color: #fff;
  background-color: #33bef2;
}

.tag-icon:focus,
.tag:focus {
  outline: 2px solid #0083b3;
}

.tag-icon:active,
.tag:active {
  outline: 0;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.tag-icon {
  padding-right: 1.7em;
}

.tag-icon:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -7px;
}

.tag-secondary {
  background-color: #00928f;
}

.tag-secondary:hover {
  background-color: #007572;
}

.tag-secondary:focus {
  outline: 2px solid #006e6b;
}

.tag-secondary:active {
  outline: 0;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.text-line {
  margin-bottom: .5em;
  overflow: hidden;
  text-align: center;
}

.text-line .text-line-text {
  font-size: 1em;
}

.text-line .text-line-text:after,
.text-line .text-line-text:before {
  content: "";
  display: inline-block;
  zoom: 1;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
  background-color: #d1d1d1;
}

.text-line .text-line-text:before {
  right: .5em;
  margin-left: -50%;
}

.text-line .text-line-text:after {
  left: .5em;
  margin-right: -50%;
}

.block {
  margin-bottom: 2em;
}

.block-head {
  padding: 1em;
  background-color: #00aeef;
}

@media (min-width: 767px) {
  .block-head {
    padding: 1.5em 1.6em;
  }
}

.block-head--icon {
  position: relative;
  padding-right: 4em;
}

.block-head--icon:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -.5em;
  font-size: 1.3em;
  color: #fff;
}

@media (min-width: 767px) {
  .block-head--icon:after {
    font-size: 24px;
  }
}

.block-title {
  position: relative;
  color: #fff;
  font-size: 1.1em;
  margin-bottom: 0;
  font-weight: 400;
}

@media (min-width: 767px) {
  .block-title {
    font-size: 1.2em;
  }
}

.block-body {
  padding: 1em;
  word-break: break-word;
  background-color: #f4f4f4;
}

.block-footer {
  padding: 15px;
}

.CookieConsent {
  padding: 10px;
  background: #26a;
  color: #fff !important;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 22;
  -webkit-animation: anim .8s ease-out;
  animation: anim .8s ease-out;
}

.CookieConsent a {
  color: #fff;
}

@-webkit-keyframes anim {
  0% {
    top: -111px;
  }

  100% {
    top: 0;
  }
}

@keyframes anim {
  0% {
    top: -111px;
  }

  100% {
    top: 0;
  }
}

.ConsentButtons input {
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 0;
}

.unsubscribe {
  width: 52%;
  margin: auto;
  padding-top: 30px;
  min-height: 433px;
}

.unsubscribe .UnsubscriptionEmail {
  margin-bottom: 20px;
}

.speechBubbleBtn {
  margin-top: 12px;
  cursor: pointer;
}

blockquote.beneath::after {
  display: none;
}

blockquote.beneath {
  margin-top: 10px;
  display: none;
  position: relative;
  max-width: 400px;
}

blockquote.beneath::before {
  content: "";
  display: block;
  position: absolute;
  border: transparent 10px solid;
  border-bottom-color: #c3d0e4;
  top: -20px;
}

.blockquoteTarget {
  clear: both;
  width: 100%;
  display: none;
  position: relative;
  padding: .8em 1.3em;
  margin-top: .6em;
  line-height: 1.6;
  background-color: #c3d0e4;
  color: #21607a;
}

.blockquoteTarget::before {
  content: "";
  display: block;
  position: absolute;
  border: transparent 10px solid;
  border-bottom-color: #c3d0e4;
  top: -20px;
}

.FormPanel table,
.panel {
  padding: 1.5em;
  margin-bottom: 2em;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.22);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
}

.FormPanel td {
  padding: 1em;
}

.landing-strip .panel-related {
  width: 100%;
}

@media (min-width: 767px) {
  .landing-strip .panel-related {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media (min-width: 992px) {
  .landing-strip .panel-related {
    width: 31%;
    margin-left: 1.1%;
    margin-right: 1.1%;
  }
}

.panel-related ul {
  margin: 0;
}

.panel-related .panel-list a {
  display: block;
}

@media (max-width: 992px) {
  .panel-related .panel-list .widget-image {
    width: 100%;
    float: none;
    margin: 0 0 1em;
  }
}

.panel-title {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 700;
  color: #333;
}

.panel-date {
  display: block;
  margin-bottom: 1em;
  font-size: .8em;
}

.panel-body {
  font-size: .9em;
}

.panel-person {
  background-color: #fff;
  float: left;
}

.panel-person-title {
  margin-bottom: 0;
  font-size: 1.3em;
  font-weight: 400;
  color: #00928f;
}

.panel-person-role {
  display: block;
  margin-bottom: 1em;
  font-size: .9em;
  font-weight: 700;
}

.msStretchBg {
  width: 110vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  overflow: auto;
}

.msStretchBg .widgetMsStretchBg {
  background: 0 0;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.msStretchBg .widget-wrapper {
  float: left;
  margin-right: 30px;
}

@media (min-width: 992px) {
  main {
    overflow-x: hidden;
  }
}

.msFlipContainer {
  position: relative;
  -webkit-perspective: 1000;
  perspective: 1000;
  margin: 0 0 20px 0;
}

.msFlipCard {
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: .5s;
  transition: .5s;
}

.msFlipContainer:hover .msFlipCard {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.msFlipFace {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.msNoHide .msFlipFace {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

.msFlipFront {
  background: #fff;
  z-index: 10;
}

.msFlipBack {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: #fff;
}

.msFlipContainer:hover .msFlipFront {
  z-index: 0;
}

.news-meta {
  margin: 0 0 .5em 1.5em;
}

.news-meta>ul {
  margin: 0;
}

.news-meta>ul>li {
  margin-bottom: .4em;
  font-size: .9em;
}

.news-meta>ul>li:last-child {
  margin: 1.3em 0 1em -1.5em;
}

@media (min-width: 767px) {
  .news-meta>ul>li:last-child {
    float: right;
    margin: 0;
  }
}

@media (min-width: 480px) {
  .news-meta>ul>li {
    font-size: 1em;
  }
}

@media (min-width: 767px) {
  .news-meta>ul>li {
    display: inline-block;
    margin: 0 3.2em .7em 0;
  }
}

@media (min-width: 1200px) {
  .news-meta>ul>li {
    margin-right: 5.2em;
  }
}

.news-meta>ul>li * {
  color: #a4a4a4;
}

.news-meta li {
  list-style: none !important;
}

.newsmdFix {
  margin-left: 1.5em;
}

.news-meta-item {
  position: relative;
}

.news-meta-item:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: .15em;
  left: -1.5em;
}

.news-meta-item>a:hover {
  color: #838383;
}

.news-meta-comments:before {
  top: .3em;
}

.news-meta-share {
  z-index: 3;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.news-meta-share a {
  margin: 0;
  color: #fff !important;
}

.eventsShare div.share {
  top: -45px;
  left: 195px;
  position: relative;
}

.publication-meta .btn-share {
  margin-left: 20px;
}

.publication-meta div.share {
  top: 104px;
  left: 175px;
}

.tags-meta {
  position: relative;
  padding: .5em 0 .3em;
  border-top: 2px dashed #eaeaea;
  border-bottom: 2px dashed #eaeaea;
}

.tags-meta:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
}

.tags-meta ul {
  margin: 0;
}

@media (min-width: 767px) {
  .tags-meta {
    padding: 1em 0 1em 2em;
  }

  .tags-meta:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
    font-size: 1.1em;
    color: #bbb;
  }
}

.tags-meta .tag {
  margin-bottom: .4em;
  background-color: #00928f;
}

.tags-meta .tag:hover {
  background-color: #008381;
}

.comments textarea {
  min-height: 12.5em;
}

.comment {
  margin-bottom: 2em;
}

.comment-name {
  margin-bottom: .3em;
  font-size: 1em;
  font-weight: 700;
  color: #444;
}

.comment-name a {
  color: #444;
}

.comment-date {
  margin-left: 1.3em;
  margin-bottom: 1em;
  font-size: .9em;
  color: #a4a4a4;
}

@media (min-width: 992px) {
  .comment-date {
    text-align: right;
  }
}

.comment-desc {
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: 300;
}

.comment-actions a {
  padding-right: .5em;
}

.comment-actions a:after {
  content: "|";
  margin-left: .5em;
  color: #aaa;
}

.comment-actions a:last-child:after {
  content: "";
}

.CheckBoxMovedLeft input[type=checkbox] {
  display: inline;
}

.CheckBoxMovedLeft label {
  display: inline;
}

.share {
  position: absolute;
  top: -2.7em;
  left: 50%;
  margin-left: -6.1em;
  opacity: 1;
  filter: "alpha(opacity=1)";
  -khtml-opacity: 1;
  -moz-opacity: 1;
  zoom: 1;
  width: 12.5em;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, transform .3s;
  transition: opacity .3s, transform .3s, -webkit-transform .3s;
  z-index: 1;
}

.share.js-hidden {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: opacity .2s, -webkit-transform .4s;
  transition: opacity .2s, -webkit-transform .4s;
  transition: transform .4s, opacity .2s;
  transition: transform .4s, opacity .2s, -webkit-transform .4s;
  pointer-events: none;
}

.share-buttons {
  margin: 0;
}

.share-buttons>li {
  display: inline-block;
  zoom: 1;
}

.share-buttons>li>a {
  display: inline-block;
  zoom: 1;
  position: relative;
  width: 2.5em;
  height: 2.5em;
  -webkit-transition: all 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: all 0.2s cubic-bezier(0.14, 0, 0.79, 1);
}

.share-buttons>li>a:hover:before {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.share-buttons>li>a:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -.5em;
  margin-left: -.5em;
  font-size: 1.4em;
  color: #fff;
}

.share-facebook {
  background-color: #3c599b;
}

.share-facebook:hover {
  background-color: #506aa5;
}

.share-twitter {
  background-color: #5eaade;
}

.share-twitter:hover {
  background-color: #6eb3e1;
}

.share-googleplus {
  background-color: #dd4b39;
}

.share-googleplus:hover {
  background-color: #e05d4d;
}

.share-linkedin {
  background-color: #0e75b0;
}

.share-linkedin:hover {
  background-color: #2683b8;
}

.share-mail {
  background-color: #a30046;
}

.share-mail:hover {
  background-color: #ac1a59;
}

.social-link {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: .1em;
  border-radius: 50%;
  background-color: #a9a9a9;
}

.social-link:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 21px;
  top: 4px;
  left: 5px;
  color: #fff;
}

.social-link:hover {
  background-color: #989898;
}

.social-link-facebook:before {
  top: 5px;
}

.social-link-googleplus:before {
  top: 5px;
}

.social-link-linkedin:before {
  font-size: 20px;
  top: 5px;
  left: 6.6px;
}

.social-link-instagram:before {
  font-size: 20px;
  top: 5px;
  left: 6.6px;
}

.social-link-twitter:before {
  top: 5.4px;
  left: 5.6px;
}

.ms-wysiwyg {
  margin-bottom: 2em;
}

.ms-wysiwyg a:link,
.ms-wysiwyg a:visited {
  color: #4e85bb;
}

.ms-wysiwyg a:active,
.ms-wysiwyg a:focus,
.ms-wysiwyg a:hover {
  color: #26a;
}

.ms-wysiwyg em {
  font-style: italic;
}

.ms-wysiwyg img {
  width: auto !important;
  height: auto !important;
}

.ms-wysiwyg img.left {
  float: left;
  margin: 0 1em 0 0;
}

.ms-wysiwyg img.right {
  float: right;
  margin: 0 0 0 1em;
}

@media (max-width: 767px) {
  .ms-wysiwyg iframe {
    width: 100% !important;
  }
}

.ms-wysiwyg blockquote {
  padding: 1.7em;
}

.landing-sector-description ul,
.ms-wysiwyg ul,
.msEventWidget ul,
.news-post ul,
.person-bio ul,
.sitemap ul,
.vacancy-description ul,
.widget-content ul {
  margin-left: 1em;
  padding: 0;
}

.landing-sector-description ul>li,
.ms-wysiwyg ul>li,
.msEventWidget ul>li,
.news-post ul>li,
.person-bio ul>li,
.sitemap ul>li,
.vacancy-description ul>li,
.widget-content ul>li {
  margin-bottom: .5em;
  list-style-type: disc;
}

.landing-sector-description ul ul,
.ms-wysiwyg ul ul,
.msEventWidget ul ul,
.news-post ul ul,
.person-bio ul ul,
.sitemap ul ul,
.vacancy-description ul ul,
.widget-content ul ul {
  margin: 10px 0 10px 25px;
}

.widget-secondary-blue ul {
  color: #fff;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    zoom: 1;
  }

  100% {
    opacity: 1;
    -ms-filter: "alpha(opacity=1)";
    zoom: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    zoom: 1;
  }

  100% {
    opacity: 1;
    -ms-filter: "alpha(opacity=1)";
    zoom: 1;
  }
}

.mstphAccordionTilePanel {
  margin-bottom: 2em;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.22);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  background-color: #fff;
}

.mstphAccordionTileTitle {
  padding: 1em;
  background-color: #26a;
  color: #fff;
}

.mstphAccordionTileTitle:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: icon-font;
  content: "\e604";
  float: right;
  margin-top: -18px;
}

.mstphAccordionTileTitle span.widget-title {
  margin-bottom: 0;
}

.mstphAccordionTileTitle.selected:after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: icon-font;
  content: "\e604";
  -webkit-transform: rotateZ(180deg) translateY(-1px);
  transform: rotateZ(180deg) translateY(-1px);
  float: right;
  margin-top: -18px;
}

.mstphAccordionTileTitleNoArrow:after {
  display: none;
}

.mstphAccordionTileContent {
  padding: .5em 1em .5em 1em;
}

.PollControl {
  padding: 1.2em .8em;
  margin-bottom: 1em;
  border-radius: 5px;
  background: #f8f8f8;
}

.PollControl .PollTitle {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1em;
  color: #21607a;
  display: block;
}

.PollControl .PollQuestion {
  position: relative;
  font-size: 1.1em;
  color: #fff;
  background: #00928f;
  display: block;
  padding: 13px;
  border-radius: 4px;
  line-height: 1.2;
  padding-right: 49px;
  margin-bottom: .5em;
}

.PollControl label {
  display: inline !important;
}

.PollControl td {
  border: 0;
  padding: 0;
}

.PollControl .PollAnswers {
  background: #f8f8f8;
  padding: 0 10px 21px;
  color: #373232;
  border-radius: 5px;
}

.PollControl .PollAnswers .PollAnswer {
  margin-bottom: 3px;
  display: inline-block;
  border-top: 2px dashed #999;
  display: block;
  padding-top: 9px;
}

.PollControl .PollAnswers .PollAnswer .PollAnswerText {
  font-size: 1.2em;
}

.PollControl .PollAnswers .PollAnswer .PollAnswerRadio>label {
  display: inline;
  margin-left: 5px;
}

.PollControl .PollAnswers .PollCount {
  font-size: 1em;
}

.PollControl .PollResult {
  font-size: 1.2em;
}

.PollControl tbody tr:nth-of-type(1) .PollAnswer {
  border-top: 0;
}

.PollControl .PollGraph {
  margin-right: 10px;
  background: #eaeaea;
  margin-bottom: 8px;
}

.PollControl .PollGraph>div {
  border-radius: 5px;
  background: #26a;
  margin-bottom: 18px;
}

.PollControl .ErrorMessage {
  color: #fff;
  display: block;
  background: #3c3939;
  padding: 10px;
  font-weight: 700;
  border-radius: 3px;
}

.PollControl .PollFooter .PollVoteButton {
  margin-bottom: 0;
}

#map-canvas {
  height: 400px;
  width: 100%;
}

#map-canvas img {
  max-width: none;
}

@media (max-width: 767px) {
  #map-canvas {
    display: none;
  }
}

.js-hidden {
  opacity: 0;
  filter: "alpha(opacity=0)";
  zoom: 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.overlay {
  background-color: #000;
  opacity: .6;
  filter: "alpha(opacity=.6)";
  zoom: 1;
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.14, 0, 0.79, 1);
  transition: all 0.2s cubic-bezier(0.14, 0, 0.79, 1);
}

@media (min-width: 992px) {
  .overlay {
    display: none !important;
  }
}

.js-directory-searchable-input,
.js-directory-submit input {
  display: none;
}

.js-page-results-data {
  padding: .6em 0 0 1.3em;
  display: inline-block;
}

.no-js .js-directory-searchable-input {
  display: block;
}

.no-js .js-directory-submit input {
  display: inline;
}

.no-js .ms-directory .js-btn-submit {
  display: none;
}

.no-js .ms-directory .form-group-submit {
  margin-bottom: 0;
}

.no-js .ms-directory .js-directory-form-inputs,
.no-js .ms-directory .upper-elements {
  margin-bottom: 2em;
}

.no-js .ms-directory .strip {
  display: none;
}

.isotope,
.panel-related {
  -webkit-transition-duration: 0;
  transition-duration: 0;
}

.js-carousel {
  display: none;
}

.upper-elements {
  -webkit-animation: fadeIn 0.1s cubic-bezier(0.14, 0, 0.79, 1);
  animation: fadeIn 0.1s cubic-bezier(0.14, 0, 0.79, 1);
}

@media (min-width: 992px) {
  .upper-elements {
    background-size: cover;
  }
}

.site-header {
  background-color: #fbfbfb;
  padding-bottom: .9em;
}

@media (min-width: 767px) {
  .site-header {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .site-header {
    background-color: #fff;
  }
}

@media (max-width: 992px) {
  .site-header .container {
    width: 100%;
  }
}

.site-branding {
  padding: 1em 0 .4em;
}

@media (min-width: 767px) {
  .site-branding {
    padding: 1.5em 0;
  }
}

.site-logo {
  display: block;
  line-height: 0;
  text-align: center;
}

@media (min-width: 767px) {
  .site-logo {
    text-align: left;
    padding-right: .8em;
    margin-right: .8em;
    float: left;
  }
}

@media (min-width: 767px) {
  .nav-secondary li>a {
    font-size: .8em;
  }
}

.imgSecondLogoContainer {
  text-align: center;
  margin-top: 10px;
}

@media (min-width: 767px) {
  .imgSecondLogoContainer {
    display: inline-block;
    position: relative;
    top: 10px;
  }
}

.imgSecondLogo {
  margin-top: 10px;
  height: 33px;
}

@media (min-width: 992px) {
  .imgSecondLogo {
    margin-left: 40px;
  }
}

@media (max-width: 992px) {
  .imgSecondLogo {
    margin-left: 40px;
  }
}

@media (max-width: 767px) {
  .imgSecondLogo {
    display: block;
    margin: auto;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .imgSecondLogo {
    display: block;
    margin: auto;
    margin-top: 10px;
  }
}

.imgSecondLogoContainerLeft {
  text-align: center;
  padding-top: 20px;
  padding-left: 20px;
}

@media (min-width: 767px) {
  .imgSecondLogoContainerLeft {
    display: inline-block;
    top: 10px;
  }
}

@media (min-width: 992px) {
  .imgSecondLogoContainerLeft {
    padding-top: 0;
  }
}

.imgSecondLogoLeft {
  margin-top: 10px;
  height: 33px;
}

@media (min-width: 992px) {
  .imgSecondLogoLeft {
    margin-left: 40px;
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .imgSecondLogoLeft {
    margin-left: 40px;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .imgSecondLogoLeft {
    display: block;
    margin: auto;
    margin-top: 10px;
  }

  .site-identity {
    padding-top: 15px;
    margin-left: 20px;
  }
}

@media (max-width: 480px) {
  .imgSecondLogoLeft {
    display: block;
    margin: auto;
    margin-top: 10px;
  }

  .site-identity {
    font-size: 18px !important;
    padding-top: 20px;
    margin-left: 15px;
  }
}

.site-identity {
  font-family: Cambria !important;
  text-align: center;
  font-style: italic;
  font-size: 2rem;
}

.site-identity .subtitle {
  font-family: inherit;
  font-style: inherit;
  font-size: 80%;
}

@media (min-width: 767px) {
  .site-identity {
    display: block;
    float: left;
    font-size: .9em;
    margin-top: .4em;
  }
}

.site-meta {
  text-align: right;
}

@media (min-width: 480px) and (max-width: 992px) {
  .site-meta {
    margin-top: 8px;
  }
}

.site-meta .languageSelection,
.site-meta .social {
  display: block;
  text-align: center;
}

@media (min-width: 767px) {

  .site-meta .languageSelection,
  .site-meta .social {
    margin: 0 0 0 .8em;
    display: inline-block;
    position: relative;
    top: 0;
  }
}

.languageSelection a span {
  font-size: .8rem;
  background: #50c8e8;
  color: #fff;
  padding: 2px;
}

.site-search {
  position: absolute;
  top: 2px;
  z-index: 10;
  width: 100%;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, transform .3s;
  transition: opacity .3s, transform .3s, -webkit-transform .3s;
}

.site-search input {
  line-height: 2;
}

.site-search input:focus {
  outline: 0;
}

.form-light .site-search input {
  border: 1px solid #bbb;
}

@media (min-width: 992px) {
  .site-search {
    top: 133%;
    width: 240%;
    left: -140%;
  }
}

.site-search.js-hidden {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: opacity .2s, -webkit-transform .4s;
  transition: opacity .2s, -webkit-transform .4s;
  transition: transform .4s, opacity .2s;
  transition: transform .4s, opacity .2s, -webkit-transform .4s;
  pointer-events: none;
}

.nav-primary {
  top: 0;
  width: 100%;
  position: relative;
}

.nav-primary li ul {
  display: none;
}

.nav-primary li ul li {
  z-index: 999;
}

.nav-primary li ul li a {
  z-index: 999;
}

@media (min-width: 992px) {
  .nav-primary li ul {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    opacity: 0;
    filter: "alpha(opacity=0)";
    zoom: 1;
    visibility: hidden;
    padding: 1em;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.28);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.28);
    border-radius: 0 0 4px 4px;
    -webkit-transition: all 0.2s cubic-bezier(0.14, 0, 0.79, 1);
    transition: all 0.2s cubic-bezier(0.14, 0, 0.79, 1);
    overflow: hidden;
  }

  .nav-primary li ul:after {
    content: "";
    display: table;
    clear: both;
  }

  .nav-primary li ul>li {
    float: left;
    width: 50%;
  }

  .nav-primary li ul>li.Highlighted>a {
    background-color: #fff;
    color: #888;
  }

  .nav-primary li ul>li.Highlighted>a:after {
    display: none;
  }

  .nav-primary li ul>li>a {
    display: block;
    color: #21607a;
    background: #f5f5f5;
    padding: .5em 1em;
    margin: .2em;
    font-size: .9em;
    text-transform: none;
  }

  .nav-primary li ul>li>a:hover {
    background-color: #00aeef;
    color: #fff;
  }
}

@media (min-width: 1200px) {
  .nav-primary li ul {
    width: 48em;
  }
}

@media (max-width: 992px) {
  .nav-primary {
    position: relative !important;
  }

  .nav-primary .container {
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;
  }

  .nav-primary li.Highlighted a:after {
    display: none;
  }
}

@media (min-width: 992px) {
  .nav-primary {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.64);
    z-index: 11;
  }
}

.nav-primary li {
  display: block;
}

.nav-primary li:last-child>a {
  border-bottom: 0;
}

@media (min-width: 992px) {
  .nav-primary li {
    display: inline-block;
  }
}

.nav-primary li>a {
  display: block;
  position: relative;
  padding: .7em 1em;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.03);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  font-weight: 700;
}

@media (min-width: 992px) {
  .nav-primary li>a {
    display: inline-block;
    padding: 1.2em 0;
    margin-right: 2.5em;
    background-color: transparent;
    border: none;
    font-weight: 400;
  }
}

.nav-primary li.Highlighted>a {
  border-left: 2px solid #ffc526;
}

.nav-primary li.Highlighted>a:hover {
  cursor: default;
}

.nav-primary li.Highlighted>a:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 4px solid #00aeef;
}

@media (min-width: 992px) {
  .nav-primary li.Highlighted>a {
    border-left: 0;
  }
}

@media (min-width: 992px) {
  .nav-primary li:hover ul {
    visibility: visible;
    opacity: 1;
    filter: "alpha(opacity=1)";
    zoom: 1;
  }
}

.nav-secondary {
  text-align: center;
  margin-bottom: .5em;
}

.nav-secondary li>a {
  padding: .5em .6em;
  font-size: .8em;
}

@media (min-width: 767px) {
  .nav-secondary {
    text-align: right;
    display: inline-block;
    margin-bottom: 0;
  }

  .nav-secondary li>a {
    text-decoration: none;
    padding-left: 1em;
    padding: 1.8em 0 1.9em 1em;
    color: #333;
  }

  .nav-secondary li>a:hover {
    text-decoration: underline;
    color: #474747;
  }
}

@media (min-width: 1200px) {
  .nav-secondary li>a {
    font-size: 1em;
  }
}

.nav-tertiary-collapse {
  display: none;
}

@media (min-width: 992px) {
  .nav-tertiary-collapse {
    display: block !important;
  }
}

.nav-tertiary ul {
  margin-bottom: 2em;
}

.nav-tertiary li {
  display: block;
  width: 100%;
}

.nav-tertiary li>a {
  display: block;
  padding: .4em 0 .4em 1.3em;
  margin-bottom: 0;
  font-size: .9em;
  color: #21607a;
  border-left: 2px solid #00aeef;
  background: 0 0;
  line-height: 1.1;
}

.nav-tertiary li>a:hover {
  color: #00aeef;
}

.nav-tertiary li.Highlighted a:first-child {
  color: #6fcbed;
}

.nav-tertiary li.Highlighted ul a {
  color: #333 !important;
}

@media (min-width: 992px) {
  .nav-sector-toggle {
    display: none;
  }
}

@media (min-width: 992px) {
  .nav-service-toggle {
    display: none;
  }
}

.nav-list a,
.nav-list li {
  display: block;
}

.nav-primary-buttons {
  position: relative;
  text-align: center;
  padding: .3em 0;
  background: #ebebeb;
  border-bottom: 3px solid #d6d6d6;
}

.nav-primary-buttons .menu-link {
  float: left;
  padding: 4px 3px;
  margin-left: 13px;
  background: #50c8e8;
  color: #fff;
  border-radius: 6px;
}

.nav-primary-buttons .menu-link .menu-icon {
  font-size: 18px;
}

.nav-primary-buttons .menu-link .menu-text {
  margin-right: 2px;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .nav-primary-buttons .menu-link {
    display: none;
  }
}

.nav-primary-buttons .nav-primary-buttons-wrapper {
  float: right;
  margin-right: 12px;
}

@media (min-width: 992px) {
  .nav-primary-buttons .nav-primary-buttons-wrapper {
    float: none;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .nav-primary-buttons {
    background-color: transparent;
    text-align: right;
    border: 0;
    padding: .82em 0 0;
  }
}

.nav-button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: .2em;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #50c8e8;
}

.nav-button:hover {
  background-color: #00a4e1;
}

.nav-button:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 6px;
  left: 7px;
  color: #fff;
  font-size: 1.1em;
}

.landing-strip .nav-tertiary li>a {
  background-color: #e8e8e8;
}

@media (min-width: 767px) {
  .landing-strip .nav-tertiary li>a {
    border-left: 2px solid #00aeef;
    background: 0 0;
    margin-bottom: 0;
  }
}

.menu-panel {
  position: fixed;
  left: -15.625em;
  width: 15.625em;
}

@media (max-width: 992px) {
  .menu-panel {
    background: #256b85;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #256b85), to(#196784));
    background: linear-gradient(to bottom, #256b85 0, #196784 100%);
    -webkit-box-shadow: inset -2px 0 14px rgba(0, 0, 0, 0.15);
    box-shadow: inset -2px 0 14px rgba(0, 0, 0, 0.15);
    border-top: 11px solid #074a62;
  }
}

@media (min-width: 992px) {
  .menu-panel {
    position: static !important;
    width: auto !important;
  }
}

.wrap {
  position: relative;
}

.item {
  background-size: cover;
  background-position: center;
}

.banner-container {
  background-color: rgba(0, 0, 0, 0.12);
}

@media (min-width: 767px) {
  .banner-container {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

@media (min-width: 992px) {
  .banner-container {
    padding-top: 70px;
  }
}

.banner-container--centered {
  display: block;
  width: 100%;
  min-height: 200px;
}

@media (min-width: 992px) {
  .banner-container--centered {
    display: table;
  }
}

.banner-container--centered>.banner-content {
  display: table-cell;
  vertical-align: middle;
}

.darkBackPanel {
  background: rgba(0, 0, 0, 0.3);
}

.banner-content {
  padding: 1em 0;
}

@media (min-width: 767px) {
  .banner-content {
    padding: 1em 2em;
    position: relative;
  }
}

@media (min-width: 992px) {
  .banner-content {
    height: 323px;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
  }
}

@media (min-width: 1240px) {
  .banner-content {
    height: 360px;
    padding: 0;
    position: relative;
  }
}

@media (min-width: 767px) {
  .owl-buttons {
    display: block;
    width: 100%;
  }

  .owl-buttons .owl-next,
  .owl-buttons .owl-prev {
    position: absolute;
    top: 50%;
    margin-top: -14px;
  }
}

@media (min-width: 767px) and (min-width: 992px) {

  .owl-buttons .owl-next,
  .owl-buttons .owl-prev {
    margin-top: 0;
  }
}

@media (min-width: 767px) {
  .owl-buttons .owl-prev {
    height: 0;
    width: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-right: 18px solid #fff;
    left: 1%;
  }

  .owl-buttons .owl-next {
    height: 0;
    width: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 18px solid #fff;
    right: 1%;
  }
}

@media (min-width: 1530px) {
  .owl-buttons .owl-prev {
    left: 10%;
  }

  .owl-buttons .owl-next {
    right: 10%;
  }
}

.banner-content-text {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.29);
  position: absolute;
}

.banner-content-text * {
  color: #fff;
}

.banner-content-text :last-child {
  margin-bottom: 0;
}

@media (min-width: 767px) {
  .banner-content-text {
    width: 60%;
    position: relative;
  }
}

@media (min-width: 992px) {
  .banner-content-text {
    width: 46%;
    position: relative;
  }
}

.banner-content--right .banner-content-text {
  float: right;
}

@media (min-width: 480px) {
  .banner-content--right .banner-content-text {
    margin-right: 3em;
    text-align: left;
  }
}

@media (min-width: 767px) {
  .banner-content--right .banner-content-text {
    text-align: right;
  }
}

@media (min-width: 1530px) {
  .banner-content--right .banner-content-text {
    margin-right: 0;
    text-align: right;
  }
}

.banner-title {
  font-weight: 300;
  margin-bottom: .5em;
  font-size: 1.4em;
}

@media (min-width: 767px) {
  .banner-title {
    font-size: 2.4em;
  }
}

.banner-description {
  font-weight: 300;
  font-size: 1.3em;
}

@media (min-width: 767px) {
  .banner-description {
    font-size: 1.5em;
  }
}

.owl-pagination {
  text-align: center;
  margin: auto;
}

.owl-pagination .owl-page {
  border-radius: 50%;
  background: #a4a4a4;
  width: 16px;
  height: 16px;
  display: inline-block;
  --float: left;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.owl-pagination .owl-page.active {
  background: #50c8e8;
}

@media (min-width: 767px) {
  .owl-pagination {
    display: none;
  }
}

.owl-item .item {
  min-height: 200px;
}

.bannerItem {
  min-height: 200px;
}

.share-googleplus {
  display: none !important;
}

.site-footer {
  padding: 2em 0;
  background: #edebeb url(/dist/img/branding/swoop.png) no-repeat bottom right;
}

.site-footer h4 {
  font-size: 1.3em;
  font-weight: 400;
  color: #00aeef;
}

.site-footer ul {
  margin-bottom: 2em;
}

.site-footer a:hover {
  text-decoration: underline;
}

.footer-branding {
  text-align: center;
}

.footer-branding img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
}

.footer-branding a {
  display: block;
  color: #00aeef;
}

.footer-nav a {
  font-size: .95em;
  line-height: 1;
  padding-bottom: .6em;
}

.corp-links {
  padding: 1em 0;
}

.corp-links .social {
  text-align: center;
  margin: 0;
}

@media (min-width: 992px) {
  .corp-links .social {
    text-align: left;
    position: relative;
    top: 4px;
  }
}

.copyright {
  text-align: center;
  margin-bottom: 1em;
}

.copyright small {
  display: inline-block;
  font-size: .9em;
  color: #aaa;
}

@media (min-width: 767px) {
  .copyright small {
    margin-right: 1em;
  }
}

.copyright ul {
  display: inline-block;
  margin: 0;
}

.copyright li {
  display: inline-block;
}

.copyright li>a {
  position: relative;
  font-size: .9em;
  color: #a9a9a9;
  text-decoration: underline;
  margin: 0 1em;
}

.copyright li>a:hover {
  color: #989898;
}

@media (min-width: 767px) {
  .copyright li {
    position: relative;
  }

  .copyright li:before {
    content: "|";
    position: absolute;
    left: 0;
    color: #aaa;
    text-decoration: none;
  }

  .copyright li>a {
    margin: 0 1.3em;
  }
}

@media (min-width: 992px) {
  .copyright {
    text-align: left;
    margin-bottom: 0;
    position: relative;
    top: 6px;
  }
}

.contact-card {
  padding: .7em 0;
  margin-left: 1.1em;
  margin-right: 1.1em;
  border-bottom: 1px solid #aaa;
}

.contact-card:last-child {
  border-bottom: 0;
}

.contact-card a:not(.btn) {
  color: inherit;
  font-weight: 300;
  font-size: 1em;
}

.contact-piece {
  margin-bottom: 1em;
  position: relative;
}

.contact-piece * {
  margin-bottom: 0;
}

.contact-piece .split {
  margin-bottom: 0;
}

.contact-piece:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: .3em;
  left: -1.2em;
  color: #a30046;
}

.contact-piece.contact-inline .contact-title {
  display: inline-block;
}

.contact-data,
.contact-title {
  font-size: 1em;
  margin-bottom: 0;
  color: inherit;
}

.contact-title {
  font-weight: 400;
  line-height: 1.3;
}

.contact-data {
  font-weight: 300;
}

.contact-tel .contact-title {
  width: 45px;
}

.contact-person a {
  display: block;
}

.contact-website a {
  display: block;
  font-weight: 400 !important;
}

.contact-address a {
  display: block;
}

.contact-email a {
  display: block;
}

.person-profile h1 {
  margin-bottom: 0;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.2;
  color: #00aeef;
}

.person-profile h2 {
  font-size: 1.4em;
  font-weight: 300;
  margin-bottom: 1em;
}

@media (min-width: 767px) {
  .person-profile h2 {
    margin-bottom: .5em;
  }
}

.person-profile h3 {
  margin-bottom: .1em;
}

.person-bio {
  margin-bottom: 2em;
}

.contact-sidebar .contact-data {
  max-width: 84%;
}

.person-contact-details {
  margin-bottom: 1em;
}

.person-contact-detail {
  font-size: 1.2em;
  font-weight: 300;
}

.person-contact-details-title {
  margin-bottom: 0;
}

.person-contact-detail-email {
  margin-bottom: 15px;
}

.person-contact-detail-linkedIn {
  font-size: .9rem;
}

.person-contact-detail-twitter {
  font-size: .9rem;
}

.person-contact-detail-profileDoc {
  font-size: .9rem;
}

.person-contact-detail-vcard {
  font-size: .9rem;
}

.person-contact-detail-linkedIn::before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e603";
  color: #287bbc;
  margin-left: 5px;
}

.person-contact-detail-twitter::before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e600";
  color: #00aeef;
  margin-left: 5px;
}

.person-contact-detail-profileDoc::before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e034";
  color: #00aeef;
  margin-left: 5px;
}

.person-contact-detail-vcard::before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e074";
  color: #00aeef;
  margin-left: 5px;
}

.person-top {
  margin-bottom: 20px;
}

@media (min-width: 767px) {
  .person-top .no-l-padding {
    padding-left: 0;
  }
}

.ms-person .widget-wrapper {
  padding: 0;
}

.ms-person .widget-wrapper .widget {
  padding: 2em;
}

.ms-person .widget-wrapper-rbt .widget {
  padding: 0;
}

.ms-directory .page-content {
  padding-top: 30px;
  margin-top: -30px;
  -webkit-animation: fadeIn 0.7s cubic-bezier(0.14, 0, 0.79, 1);
  animation: fadeIn 0.7s cubic-bezier(0.14, 0, 0.79, 1);
}

.search-result * {
  margin-bottom: 0;
  line-height: 1;
}

.search-result-location,
.search-result-vacancy {
  background-color: #edebeb;
  padding: 1.5em;
  margin-bottom: 2em;
}

.search-result-location .search-result-data,
.search-result-vacancy .search-result-data {
  margin-bottom: 2em;
}

@media (min-width: 992px) {

  .search-result-location .search-result-data,
  .search-result-vacancy .search-result-data {
    margin-bottom: 0;
  }
}

.search-result-location .search-result-data-title,
.search-result-vacancy .search-result-data-title {
  margin-bottom: .2em;
  font-weight: 700;
  font-size: 1.2em;
}

.search-result-location .search-result-data-fax,
.search-result-location .search-result-data-tel,
.search-result-vacancy .search-result-data-fax,
.search-result-vacancy .search-result-data-tel {
  margin-bottom: .3em;
  font-size: 1.1em;
}

.search-result-location .search-result-data-fax h4,
.search-result-location .search-result-data-tel h4,
.search-result-vacancy .search-result-data-fax h4,
.search-result-vacancy .search-result-data-tel h4 {
  display: inline;
  margin-right: .2em;
  font-size: 1em;
  font-weight: 400;
  color: #333;
}

.search-result-location .search-result-data-location,
.search-result-vacancy .search-result-data-location {
  color: #676767;
  font-weight: 400;
  font-size: 1.2em;
  margin-bottom: .8em;
}

.search-result-location .search-result-data-view-location,
.search-result-vacancy .search-result-data-view-location {
  display: block;
}

@media (min-width: 767px) {

  .search-result-location .search-result-data-view-location,
  .search-result-vacancy .search-result-data-view-location {
    display: inline-block;
  }
}

.search-result-person {
  position: relative;
  margin-bottom: 1em;
}

@media (min-width: 992px) {
  .search-result-person {
    border: 0;
  }

  .search-result-person:after {
    content: "";
    border-right: 1px solid #ccc;
    height: 100%;
    position: absolute;
    right: 17%;
    top: 0;
  }
}

.search-result-person .row {
  background-color: #edebeb;
  -webkit-box-shadow: 0 3px 0 #cfcfcf;
  box-shadow: 0 3px 0 #cfcfcf;
  position: relative;
}

@media (min-width: 480px) {
  .search-result-person .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.search-result-person:last-of-type {
  margin-bottom: 2em;
}

.search-result-person h2 {
  margin-bottom: .2em;
  font-size: 1.1em;
}

.search-result-person h3 {
  margin-bottom: .9em;
  font-weight: 300;
  font-size: 1.1em;
  color: #a30046;
}

@media (min-width: 480px) {
  .search-result-person h3 {
    margin-bottom: .6em;
  }
}

.search-result-person h4 {
  margin-bottom: .2em;
  font-size: 1em;
}

.s-result-otherLocs {
  display: none;
}

@media (min-width: 992px) {
  .s-result-otherLocs {
    display: inline-block;
  }
}

.search-results-people--1 {
  background-color: #f4f4f4;
  padding: 1em;
}

.search-results-people--1 .field {
  margin-bottom: .5em;
}

.search-results-people--1 .field a {
  -ms-word-break: break-all;
  word-break: break-all;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  font-weight: 300;
}

.search-results-people--1 .field h4 {
  font-weight: 400;
}

.search-results-people--1 .field .btn-arrow {
  font-weight: 400;
}

@media (max-width: 480px) {
  .search-results-people--1 .field .btn-arrow {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .search-results-people--1 .field {
    clear: both;
    margin-bottom: .5em;
  }
}

.search-results-people--1 .field-tel {
  margin-bottom: 1em;
}

@media (min-width: 480px) {
  .search-results-people--1 .field-tel {
    margin-bottom: .5em;
  }
}

.search-results-people--1 .field-tel a,
.search-results-people--1 .field-tel h4 {
  color: #333;
}

.search-results-people--1 h2>a {
  color: #333;
  font-weight: 400;
  font-size: 1.1em;
}

@media (min-width: 480px) {
  .search-results-people--1 h2>a {
    font-size: 1em;
  }
}

.search-results-people--1 h4 a {
  font-weight: 400;
}

@media (min-width: 480px) {
  .search-results-people--1 h4 {
    display: inline-block;
    zoom: 1;
  }
}

.search-results-people--1 .btn-arrow {
  padding-left: .3em;
  font-size: .95em;
}

.search-results-people--1 .clipped-img {
  height: 115px;
  margin-bottom: .5em;
}

@media (max-width: 480px) {
  .search-results-people--1 .clipped-img {
    height: 79px;
    width: 65px;
    margin-bottom: .5em;
    float: none;
  }
}

@media (min-width: 480px) {
  .search-results-people--1 .media-body {
    float: left;
    max-width: 70%;
  }
}

@media (min-width: 992px) {
  .search-results-people--1 .media-body {
    width: 58%;
  }
}

@media (min-width: 1200px) {
  .search-results-people--1 .media-body {
    width: 65%;
  }
}

.search-results-people--2 {
  padding: 1em;
  border-bottom: 1px solid #ccc;
}

@media (min-width: 992px) {
  .search-results-people--2 {
    border: 0;
  }
}

.search-results-people--2 .contact-address:before,
.search-results-people--2 .icon-faux-pin {
  float: left;
  margin-right: .8em;
  position: relative;
  top: 1px;
  color: #a30046;
  font-size: 1.1em;
}

.search-results-people--2 .split {
  width: 100%;
  position: relative;
}

@media (min-width: 767px) {
  .search-results-people--2 .split {
    margin: .6em 0;
  }
}

.search-results-people--2 li {
  margin-bottom: .8em;
  font-weight: 300;
}

.search-results-people--2 strong {
  display: inline-block;
  width: 145px;
  margin: 0 1.2em .3em 0;
  font-weight: 400;
}

.search-results-people--3 {
  padding: 1em;
}

.search-results-people--3 .btn {
  width: 100%;
}

.search-results-null {
  font-size: 1.1em;
  font-weight: 300;
}

.makeChildFilterRO select {
  cursor: default;
}

.s-result {
  background-color: #f7f7f7;
  border-bottom: 3px solid #b7b7b7;
  border-radius: 4px;
  margin-bottom: 2em;
}

@media (min-width: 992px) {
  .s-result {
    float: left;
    width: 49%;
    margin-right: 2%;
  }

  .s-result:nth-child(even) {
    margin-right: 0;
  }
}

.s-result.s-result-person-lean {
  margin-bottom: 0;
  border-bottom: 1px solid #b7b7b7;
}

.s-result-person-lean .s-result-sub2-title .fullName {
  font-weight: 700;
  color: #555;
}

@media (max-width: 480px) {
  .s-result-person-lean btn {
    width: auto;
  }
}

.s-result-location {
  min-height: 108px;
}

.s-result-location .pair:last-of-type {
  margin-bottom: 0;
}

.s-result-person .btn-small {
  text-align: left;
  margin-bottom: 0;
  width: 35%;
}

@media (min-width: 480px) {
  .s-result-person .btn-small {
    width: auto;
  }
}

.s-result-person .pair-primary-location {
  float: left;
  width: 100%;
  margin-right: 1%;
}

.s-result-person .pair-other-location {
  margin-bottom: 0;
  float: left;
  width: 100%;
}

@media (min-width: 480px) {
  .s-result-person .s-result-img {
    float: left;
    width: 112px;
    margin-right: 15px;
    min-height: 177px;
  }

  .s-result-person .s-result-img img {
    margin-top: 5px;
  }
}

@media (min-width: 480px) {
  .s-result-person .s-result-content-primary {
    overflow: hidden;
    padding-bottom: 20px;
  }
}

@media (min-width: 480px) {
  .s-result-person {
    min-height: 190px;
  }
}

@media (min-width: 480px) {
  .s-result-person-lean {
    min-height: 30px;
  }
}

.s-result-title {
  margin-bottom: .1em;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.1;
}

.s-result-title a {
  display: block;
}

.s-result-sub-title {
  margin-bottom: .1em;
  font-weight: 300;
  font-size: 1.2em;
  color: #666;
}

.s-result-person-lean .pair-tel {
  margin-top: .4em;
}

.s-result-person-lean .pair-tel .btn {
  margin-right: 1em;
}

.s-result-sub2-title {
  font-weight: 400;
  font-size: 1.1em;
  color: #333;
}

.s-result-person-lean .s-result-sub2-title {
  margin-bottom: 0;
  float: left;
}

@media (max-width: 480px) {
  .s-result-person-lean .s-result-sub2-title {
    float: none;
    margin-bottom: .5em;
  }
}

.s-result-person-lean .pair {
  float: right;
}

@media (max-width: 480px) {
  .s-result-person-lean .pair {
    float: none;
  }
}

.s-result-hoverBtn {
  font-weight: 400;
  font-size: 1em;
  color: #888;
  border-bottom: 2px dotted #00aeef;
  cursor: pointer;
}

.s-result-inner {
  padding: 1em;
  padding-bottom: .5em;
}

.s-result-inner-alt {
  background-color: #ebebeb;
}

.pair {
  margin-bottom: .4em;
}

.pair a.pair-item {
  color: #26a;
}

.pair a.pair-item:hover {
  color: #1a4d80;
}

.s-result-person-lean .pair {
  margin-top: -.1em;
}

.pair-title {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 700;
  color: #797979;
}

.pair-item {
  margin-bottom: 0;
}

.s-result .pair-title {
  display: inline-block;
}

.pair-other-location .pair-title,
.pair-primary-location .pair-title {
  float: left;
  padding-right: 10px;
}

.peopleList .pagination {
  margin-top: 1em;
}

.s-resultFullWidth {
  width: 100%;
}

.s-resultPublication {
  margin-bottom: 4em;
}

@media (min-width: 992px) {
  .s-resultPublication {
    float: left;
    width: 210px;
    margin-right: 80px;
  }

  .s-resultPublication:nth-child(even) {
    margin-right: 0;
  }
}

.s-resultPublicationTile a {
  height: 300px;
  position: relative;
  padding: 0 !important;
}

.s-resultPublicationTile a img {
  height: 300px;
}

.s-resultPublicationTile a div {
  padding: .6em;
  position: absolute;
  bottom: 0;
  background: rgba(79, 138, 190, 0.8);
  color: #fff;
  width: 100%;
  min-height: 64px;
}

.search-result-vacancy .search-result-data-title {
  margin-bottom: .7em;
}

.search-result-vacancy .search-result-data-location {
  margin-bottom: 0;
}

.search-result-vacancy .btn {
  display: block;
  margin-bottom: 8px;
}

.search-result-vacancy .search-result-data-view-vacancy {
  margin-bottom: .5em;
}

.search-result-vacancy .search-result-data-date,
.search-result-vacancy .search-result-data-department {
  display: block;
  margin-bottom: 1.3em;
  color: #888;
}

.search-result-vacancy ul .search-result-data-date ul,
.search-result-vacancy ul .search-result-data-department ul {
  list-style: disc;
}

.search-filters {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .search-filters {
    margin-bottom: 0;
  }
}

.search-filters p {
  display: inline-block;
  zoom: 1;
  margin-right: .6em;
  line-height: 1;
  font-weight: 300;
  font-size: 1.1em;
}

.ms-directory .upper-elements {
  margin-bottom: 0;
}

.ms-directory .upper-elements .banner-content {
  height: auto;
}

@media (min-width: 992px) {
  .ms-directory .upper-elements .banner-content {
    padding: 1em 0;
  }
}

.directory-form {
  padding: 1.3em;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.3);
}

.directory-form .col-centered {
  text-align: center;
}

.directory-form h1 {
  margin-bottom: 1em;
  font-size: 1.7em;
  font-weight: 300;
  color: #fff;
}

.directory-form .text-line-text {
  font-weight: 700;
  color: #fff;
}

.directory-form .text-line-text:after,
.directory-form .text-line-text:before {
  background-color: #666;
  background-color: rgba(255, 255, 255, 0.23);
}

.directory-form .btn-arrow-down {
  margin-bottom: 0;
}

.strip .tag {
  margin-bottom: 3px;
}

@media (min-width: 1200px) {
  .strip .tag {
    margin-bottom: 0;
  }
}

.directory-map #map-canvas,
.directory-no-map #map-canvas {
  height: 500px;
}

.directory-map .map-filter,
.directory-no-map .map-filter {
  padding: .7em 0;
  pointer-events: none;
}

.directory-map .map-filter .js-directory-filter,
.directory-map .map-filter .js-directory-filter2,
.directory-no-map .map-filter .js-directory-filter,
.directory-no-map .map-filter .js-directory-filter2 {
  width: 100%;
  pointer-events: all;
}

.directory-map .map-filter .select,
.directory-no-map .map-filter .select {
  margin-bottom: 0;
}

@media (min-width: 767px) {

  .directory-map .map-filter,
  .directory-no-map .map-filter {
    position: absolute;
    width: 100%;
    top: 60px;
    left: 0;
    z-index: 1;
  }

  .directory-map .map-filter .js-directory-filter,
  .directory-map .map-filter .js-directory-filter2,
  .directory-no-map .map-filter .js-directory-filter,
  .directory-no-map .map-filter .js-directory-filter2 {
    width: 24em;
  }
}

@media (min-width: 767px) {
  .directory-no-map .map-filter {
    position: static;
  }
}

.map-info-window {
  line-height: 1.35;
  overflow: hidden;
  white-space: nowrap;
  min-width: 250px;
}

.map-info-window .btn {
  margin-bottom: 0;
  padding: .3em .8em;
  margin-bottom: 0;
  text-align: center;
}

.map-info-window h2 {
  font-size: 1.2em;
  margin-bottom: 0;
  font-weight: 400;
  color: #444;
}

.map-info-window h3 {
  font-size: 1.2em;
  margin-bottom: .8em;
  font-weight: 700;
  color: #444;
}

.input-icon-search,
.js-directory-filter .select {
  margin-bottom: 0.5em !important;
}

.directory-people .form-group-submit {
  display: none;
}

.directory-people .btn-arrow-down {
  margin-top: .5em;
}

#results .strip {
  background: #f7f7f7;
}

.landing-sector-description {
  line-height: 1.6;
}

.landing-strips .landing-strip {
  background-color: #f7f7f7;
}

.landing-strips .landing-strip:nth-child(1) h1 {
  margin: 0 0 1em 0;
}

.landing-strip {
  padding: 2em 0;
}

.landing-strip h1 {
  margin: 1em 0 2em;
}

.landing-strip blockquote {
  font-size: 1em;
}

.landing-related h1 {
  text-align: center;
}

.ms-wysiwyg blockquote {
  font-size: 1em;
  padding: 1.2em;
}

.landing-publications-category {
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #edebeb;
}

.landing-publications-category:last-of-type {
  border-bottom: 0;
}

.publications-list a {
  display: block;
  padding: .4em 0;
}

.news-listings .news-listing:nth-child(even) {
  background-color: #fafafa;
}

.news-listing h2 {
  margin-bottom: .5em;
  font-size: 1.2em;
}

@media (min-width: 480px) {
  .news-listing h2 {
    font-size: 1.4em;
  }
}

@media (min-width: 767px) {
  .news-listing h2 {
    margin-bottom: .1em;
  }
}

.news-listing .btn {
  margin-bottom: 0;
}

.news-listing-img {
  margin-bottom: 1em;
}

.news-listing-body {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px dashed #00928f;
}

.news-listing-body a:link,
.news-listing-body a:visited {
  color: #4e85bb;
}

.news-listing-body a:active,
.news-listing-body a:focus,
.news-listing-body a:hover {
  color: #26a;
}

.news-post {
  margin-bottom: 2em;
}

.news-post h1 {
  font-size: 1.3em;
  line-height: 1.3;
}

@media (min-width: 767px) {
  .news-post h1 {
    font-size: 1.9em;
    line-height: 1.5;
  }
}

.news-post-img {
  margin-bottom: 1em;
}

.news-post-body h2,
.news-post-body h3,
.news-post-body h4,
.news-post-body h5 {
  margin-bottom: 5px;
  margin-top: 1em;
}

@media (max-width: 480px) {
  .CaptchaTable td {
    display: block;
  }

  .CaptchaTable td img {
    left: -4px;
  }
}

.CaptchaTable img {
  position: relative;
  top: 3px;
}

#comments .InfoLabel {
  font-weight: 800;
  color: #a30046;
  margin-bottom: 20px;
  display: inline-block;
}

.publication .btn-arrow {
  width: auto;
  margin-bottom: 2em;
}

.publication-img {
  float: left;
  margin-right: 1.5em;
}

.publications-list ul {
  margin-left: 0;
}

.generic-search-result .news-meta {
  margin-bottom: 0;
  margin-left: 3em;
}

.generic-search-result .news-meta li {
  margin-bottom: 0;
}

.generic-search-result .news-meta li:last-child {
  margin-bottom: 0;
}

.generic-search-result h2 {
  font-size: 1.3em;
  margin-bottom: .5em;
}

@media (min-width: 480px) {
  .generic-search-result .search-image {
    float: left;
    margin: 0 1.5em 1em 0;
  }

  .generic-search-result .search-image img {
    display: block;
    margin-top: 6px;
  }
}

.generic-search-result .search-link {
  word-break: break-all;
  clear: both;
}

.generic-search-result .search-description {
  font-size: .9em;
}

@media (min-width: 480px) {
  .generic-search-result .search-description {
    font-size: 1em;
  }
}

.date-updated {
  margin-left: 22px;
  color: #a7a7a7;
  margin-bottom: .5em;
}

.ms-sector .upper-elements {
  margin-bottom: 0;
}

@media (max-width: 992px) {

  .sectors [class*=col-sm],
  .services [class*=col-sm] {
    width: 100%;
  }
}

.location-details {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 3em;
}

.location-details h1 {
  margin-bottom: 0;
}

.location-details h2 {
  margin-bottom: 1.5em;
  font-size: 1.3em;
}

.location-img {
  margin-bottom: 1em;
}

.location-profile {
  margin-bottom: 1em;
}

.location-map {
  margin-top: 2em;
}

.contact-url {
  font-size: 1em;
}

.noLeftPad {
  padding-left: 0;
}

.widgets-wide {
  padding: 0 !important;
}

@media (min-width: 767px) {
  .widgets-wide .widget-wrapper .widget-image {
    float: left;
    width: 20.5em;
    margin: 0 1.4em 0 0;
  }

  .widgets-wide .widget-wrapper .widget-content {
    overflow: auto;
  }
}

.widgets-narrow {
  padding: 0 !important;
}

.widgets-narrow .widget-wrapper,
.widgets-wide .widget-wrapper {
  padding: 0;
}

.widget-sub-date span.icon,
.widget-sub-title2 span.icon {
  margin-right: 6px;
}

.msEventWidget .widget-image {
  float: right;
  margin-left: .8em;
}

.widget.panel {
  padding: 1.5em;
  margin-bottom: 1.3em;
}

.widget-title {
  display: block;
  margin-bottom: .8em;
  font-size: .8em;
  font-weight: 700;
  line-height: 1;
}

.widget-title.has-icon {
  position: relative;
  padding-left: 2.2em;
}

.widget-title.has-icon:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -17px;
  width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #ffc526;
  color: #fff;
}

.widget-sub-title {
  margin-bottom: .5em;
  font-size: 1em;
  font-weight: 700;
  color: #bababa;
}

.widget-image {
  margin-bottom: .8em;
}

.widget-description {
  margin-bottom: 1em;
}

.widget-link {
  padding-top: 1em;
  border-top-width: 1px;
  border-style: dashed;
  border-color: #7ec7c5;
}

.widget-link a {
  margin-bottom: 0;
  font-weight: 700;
  margin-bottom: 1px;
}

.widget-contacts .widget-title,
.widget-links .widget-title {
  padding-bottom: .6em;
  border-bottom: 1px solid #dce7ec;
}

.widget-contacts ul,
.widget-links ul {
  margin: 0;
}

.widget-contacts li>a,
.widget-links li>a {
  display: block;
  margin-bottom: .4em;
  font-weight: 700;
  color: #555;
}

.widget-contacts li>a:hover,
.widget-links li>a:hover {
  text-decoration: underline;
}

.widget-bloggers .widget-title {
  font-size: 1.4em;
  font-weight: 300;
  padding-bottom: .6em;
  border-bottom: 1px solid #dce7ec;
}

.widget-bloggers li {
  padding-left: 2.2em;
}

.widget-bloggers li>a {
  position: relative;
  margin-bottom: .8em;
}

.widget-bloggers li>a:before {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: -34px;
  margin-top: -13px;
  width: 27px;
  height: 27px;
  padding: 5px;
  border-radius: 50%;
  font-size: 17px;
  background-color: #ffc526;
  padding: 3.5px 1px 0 4.6px;
  color: #fff;
}

.manual-widgets [class*=col-] {
  position: static !important;
}

.widget-bold-green {
  background-color: #00928f;
}

.widget-bold-green .widget-sub-title {
  color: #daeceb;
}

.widget-bold-green .widget-content h2,
.widget-bold-green .widget-description,
.widget-bold-green .widget-description p p p,
.widget-bold-green .widget-links,
.widget-bold-green .widget-sub-date,
.widget-bold-green .widget-sub-title2,
.widget-bold-green .widget-title {
  color: #fff;
}

.widget-bold-green .widget-contact-name {
  color: #fff;
}

.widget-bold-green .widget-contact-role {
  color: #99d2d1;
}

.widget-bold-green .widget-link {
  border-color: #6dcecc;
}

.widget-bold-green .widget-link a {
  background-color: #2caba8;
}

.widget-bold-green .widget-description a {
  color: #fff;
}

.widget-bold-green li>a {
  color: #fff;
}

.widget-bold-blue {
  background-color: #26a;
}

.widget-bold-blue .widget-sub-title {
  color: #d5e2e8;
}

.widget-bold-blue .widget-content h2,
.widget-bold-blue .widget-description,
.widget-bold-blue .widget-description p p p,
.widget-bold-blue .widget-sub-date,
.widget-bold-blue .widget-sub-title2,
.widget-bold-blue .widget-title {
  color: #fff;
}

.widget-bold-blue .widget-contact-name {
  color: #fff;
}

.widget-bold-blue .widget-contact-role {
  color: #8bc6dc;
}

.widget-bold-blue .widget-link {
  border-color: #4d8da4;
}

.widget-bold-blue .widget-link a {
  background-color: #488ea8;
}

.widget-bold-blue li>a {
  color: #fff;
}

.widget-secondary-blue {
  background-color: #4f8abe;
}

.widget-secondary-blue .widget-sub-title {
  color: #d5e2e8;
}

.widget-secondary-blue .widget-content h2,
.widget-secondary-blue .widget-description,
.widget-secondary-blue .widget-description p p p,
.widget-secondary-blue .widget-sub-date,
.widget-secondary-blue .widget-sub-title2,
.widget-secondary-blue .widget-title {
  color: #fff;
}

.widget-secondary-blue .widget-contact-name {
  color: #fff;
}

.widget-secondary-blue .widget-contact-role {
  color: #8bc6dc;
}

.widget-secondary-blue .widget-link {
  border-color: #4d8da4;
}

.widget-secondary-blue .widget-link a {
  background-color: #488ea8;
}

.widget-secondary-blue li>a {
  color: #fff;
}

.widget-cyan {
  background-color: #00aeef;
}

.widget-cyan .widget-sub-title {
  color: #d5e2e8;
}

.widget-cyan .widget-content h2,
.widget-cyan .widget-description,
.widget-cyan .widget-description p p p,
.widget-cyan .widget-sub-date,
.widget-cyan .widget-sub-title2,
.widget-cyan .widget-title {
  color: #fff;
}

.widget-cyan .widget-contact-name {
  color: #fff;
}

.widget-cyan .widget-contact-role {
  color: #8bc6dc;
}

.widget-cyan .widget-description a {
  color: #fff;
}

.widget-cyan .widget-link {
  border-color: #4d8da4;
}

.widget-cyan .widget-link a {
  background-color: #488ea8;
}

.widget-cyan li>a {
  color: #fff;
}

.widget-light-grey {
  background-color: #ececec;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.widget-light-grey .widget-sub-title {
  color: #919090;
}

.widget-light-grey .widget-title {
  border-color: #c9cdce;
}

.widget-pastel-green {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #cce9e9;
}

.widget-pastel-green .widget-sub-title {
  color: #00a29e;
}

.widget-pastel-green .widget-title {
  border-color: #b5d2d0;
}

.widget-pastel-green .widget-description,
.widget-pastel-green .widget-description p p,
.widget-pastel-green .widget-title {
  color: #222;
}

.widget-pastel-green .widget-link {
  border-color: #88d9d7;
}

.widget-pastel-green .widget-link a {
  background-color: #2caba8;
}

.widget-pastel-blue {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #50c8e8;
}

.widget-pastel-blue .widget-sub-title {
  color: #3b88a4;
}

.widget-pastel-blue .widget-title {
  border-color: #b5d2d0;
}

.widget-pastel-blue .widget-content h2,
.widget-pastel-blue .widget-description,
.widget-pastel-blue .widget-description p,
.widget-pastel-blue .widget-sub-date,
.widget-pastel-blue .widget-sub-title2,
.widget-pastel-blue .widget-title {
  color: #222;
}

.widget-pastel-blue .widget-link {
  border-color: #b9c9cf;
}

.widget-pastel-blue .widget-link a {
  background-color: #488ea8;
}

.widget-pastel-blue .widget-description a {
  color: #000;
}

.widget-light-blue {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #c3d0e4;
}

.widget-light-blue .widget-sub-title {
  color: #3b88a4;
}

.widget-light-blue .widget-title {
  border-color: #b5d2d0;
}

.widget-light-blue .widget-content h2,
.widget-light-blue .widget-description,
.widget-light-blue .widget-description p,
.widget-light-blue .widget-sub-date,
.widget-light-blue .widget-sub-title2,
.widget-light-blue .widget-title {
  color: #222;
}

.widget-light-blue .widget-link {
  border-color: #b9c9cf;
}

.widget-light-blue .widget-link a {
  background-color: #488ea8;
}

.widget-transparent {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  text-align: center;
}

.widget-transparent .widget-sub-title {
  color: #919090;
}

.widget-transparent .widget-content h2,
.widget-transparent .widget-description,
.widget-transparent .widget-description p,
.widget-transparent .widget-sub-date,
.widget-transparent .widget-sub-title2,
.widget-transparent .widget-title {
  color: #222;
}

.widget-transparent .widget-link {
  border-color: #b9c9cf;
}

.cms-blog .widget-wrapper {
  padding: 0;
  position: static !important;
}

.site-sidebar .col-md-12 {
  padding: 0;
  float: none !important;
}

.widget-contact {
  margin-bottom: 1em;
}

.widget-contact .widget-contact-img {
  float: left;
  width: 5.2em;
  margin-right: .5em;
}

.widget-contact .widget-contact-name {
  margin-bottom: 0;
  font-size: 1.1em;
  font-weight: 700;
}

.widget-contact .widget-contact-role {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0;
}

.widget-contacts {
  margin-bottom: 1em;
  float: none;
}

.widget-wrapper-rbt {
  margin-bottom: .5em;
}

.widget-title-rbt {
  font-weight: 400;
  display: inline-block;
}

.widget-content-rbt h4 {
  margin-bottom: 0;
}

.widget-description-rbt {
  font-weight: 100;
  font-size: .9em;
  margin-bottom: 1.5em;
}

.widget-description-rbt a {
  color: #00aeef;
  font-weight: 500;
}

.paperBefore::after {
  font-family: icon-font;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e034";
  color: #00aeef;
  margin-left: 5px;
}

.msLeanEvent {
  margin-bottom: .8em;
}

.drop-text {
  padding: 15px;
  display: block;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
}

.drop-text p {
  margin: 0;
}

.stream-icons .drop-text {
  padding: 30px 0;
  background-color: #00adef;
  color: #fff;
  display: none;
}

.stream-icons .drop-text p {
  padding: 0 20px;
}

@media (min-width: 768px) {
  .stream-icons .drop-text p {
    padding: 0 105px;
  }
}

.stream-header-image {
  position: relative;
  display: inline-block;
  width: 100%;
}

.stream-header-image-div {
  text-align: right;
}

.stream-section {
  width: 100%;
}

.stream-section-head-text {
  padding-top: 10px;
  clear: both;
}

@media (min-width: 768px) {
  .stream-section-head-text {
    padding-top: 0;
  }
}

.stream-section-head-text .drop-text {
  margin-top: -13px;
  background-color: #fff;
  color: #000;
}

@media (min-width: 768px) {
  .stream-section-head-text .drop-text {
    margin-top: -1px;
  }
}

.stream-section__body {
  padding-top: 20px;
  padding-bottom: 20px;
}

.stream-section h2 {
  padding-bottom: 0;
  margin-bottom: 5px;
}

.stream-section__video {
  padding: 20px 0;
  color: #000;
}

.stream-section__video h2 {
  color: #000;
}

.stream-section .center-elem {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.stream-section iframe {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 240px;
}

@media (min-width: 768px) {
  .stream-section iframe {
    height: 280px;
  }
}

@media (min-width: 992px) {
  .stream-section iframe {
    height: 320px;
  }
}

.stream-section__container {
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .stream-section__button-holder {
    top: 0;
    position: relative;
  }
}

.stream-link-panel-outer {
  padding: 40px 0;
}

.stream-link-panel-outer h1,
.stream-link-panel-outer h2,
.stream-link-panel-outer h3,
.stream-link-panel-outer h4,
.stream-link-panel-outer h5,
.stream-link-panel-outer p {
  color: inherit;
}

.btn-stream {
  background-color: #efefef;
  color: #000;
  margin-right: 25px;
}

.btn-stream:hover {
  background-color: #fff;
  color: #000;
}

.btn-stream:focus {
  outline: 2px solid #f4f4f4;
}

.btn-stream--left {
  margin-bottom: 15px;
  position: relative;
}

.btn-stream--left :before,
.btn-stream--left:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: height 1s;
  -webkit-transition: height 1s;
}

.btn-stream--left:after {
  bottom: -30px;
  left: 19px;
  border-bottom-color: #fff;
  border-width: 30px;
}

.btn-stream--left:after {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 0;
}

.btn-stream--left.active {
  margin-bottom: 0;
}

.btn-stream--left.active:after {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 1;
}

.btn-stream--center {
  margin-bottom: 15px;
  position: relative;
}

.btn-stream--center :before,
.btn-stream--center:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: height 1s;
  -webkit-transition: height 1s;
}

.btn-stream--center:after {
  bottom: -30px;
  left: 19px;
  border-bottom-color: #fff;
  border-width: 30px;
}

.btn-stream--center:after {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 0;
}

.btn-stream--center.active {
  margin-bottom: 0;
}

.btn-stream--center.active:after {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 1;
}

.btn-stream--right {
  margin-bottom: 15px;
  position: relative;
}

.btn-stream--right :before,
.btn-stream--right:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: height 1s;
  -webkit-transition: height 1s;
}

.btn-stream--right:after {
  bottom: -30px;
  left: 19px;
  border-bottom-color: #fff;
  border-width: 30px;
}

.btn-stream--right:after {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 0;
}

.btn-stream--right.active {
  margin-bottom: 0;
}

.btn-stream--right.active:after {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 1;
}

.img-full-width {
  width: 100%;
}

.one-fifth {
  margin: 1% 2%;
  display: inline-block;
}

.js-siw {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  position: relative;
  cursor: pointer;
}

.js-siw :before,
.js-siw:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: height 1s;
  -webkit-transition: height 1s;
}

.js-siw:after {
  bottom: -30px;
  left: 19px;
  border-bottom-color: #00adef;
  border-width: 30px;
}

.js-siw:after {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 0;
}

.js-siw.active:after {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 1;
}

@media (min-width: 768px) {
  .videoCarousel .owl-item .item {
    width: 500px;
    margin: 0 auto;
  }
}

.videoCarousel .carousel-control {
  height: 60px;
  top: 175px;
  width: 80px;
  font-size: 36px;
  text-align: center;
  border-radius: 60px;
}

@media (min-width: 768px) {
  .videoCarousel .carousel-control {
    top: 240px;
  }
}

.videoCarousel .owl-prev {
  left: -80px;
}

@media (min-width: 768px) {
  .videoCarousel .owl-prev {
    left: -140px;
  }
}

.videoCarousel .owl-next {
  right: -80px;
}

@media (min-width: 768px) {
  .videoCarousel .owl-next {
    right: -140px;
  }
}

@media (max-width: 640px) {
  .stream-icons__inner {
    margin-top: 20px;
  }

  .stream-icons__inner .stream-icons__item-1 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .stream-icons__inner .stream-icons__item-2 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .stream-icons__inner .stream-icons__item-3 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .stream-icons__inner .stream-icons__item-4 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .stream-icons__inner .stream-icons__item-5 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .stream-icons__inner .stream-icons__item-6 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .stream-icons__inner .stream-icons__item-7 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .stream-icons__inner .stream-icons__item-8 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .stream-icons__inner .stream-icons__item-9 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .stream-icons__inner .stream-icons__item-10 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .stream-icons__inner .drop-text-1 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .stream-icons__inner .drop-text-2 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .stream-icons__inner .drop-text-3 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .stream-icons__inner .drop-text-4 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .stream-icons__inner .drop-text-5 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .stream-icons__inner .drop-text-6 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .stream-icons__inner .drop-text-7 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .stream-icons__inner .drop-text-8 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .stream-icons__inner .drop-text-9 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .stream-icons__inner .drop-text-10 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 10;
    order: 10;
  }
}

@media (max-width: 640px) {
  .stream-icons__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .stream-icons__inner .one-fifth {
    width: 100%;
  }

  .stream-icons__inner .drop-text {
    margin-top: 20px;
  }
}

@media (max-width: 640px) {
  .stream-icons [class*=stream-icons__item]:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.vacancy-title {
  font-weight: 500;
  margin-bottom: 0;
}

.vacancy-location {
  font-size: 1.1em;
  color: #9e9e9e;
}

.vacancy-apply {
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
}

.nav-container {
  height: 280px;
  width: 100%;
  margin-bottom: 5px;
  -webkit-transition: .7s;
  transition: .7s;
}

@media (min-width: 768px) {
  .nav-container {
    margin: 16px 0;
  }
}

.nav-container .back,
.nav-container .front {
  width: 100%;
  height: 280px;
  position: relative;
}

.nav-container .front {
  opacity: 1;
  display: block;
}

.nav-container .back {
  opacity: 0;
  display: none;
}

.nav-container:hover .front {
  opacity: 0;
  display: none;
}

.nav-container:hover .back {
  opacity: 1;
  display: block;
}

.nav-container .nav-container--title {
  color: #fff;
  text-align: center;
  width: 100%;
  top: 60%;
  position: absolute;
  font-size: 1.8em;
}

.nav-container .nav-container--image-container {
  width: 100%;
  display: block;
  top: 24%;
  position: absolute;
}

.nav-container .nav-container--image-container img {
  width: 100px;
  margin: 0 auto;
  display: block;
}

.nav-container .nav-container--back-container {
  padding: 80px 0;
}

@media (min-width: 768px) {
  .nav-container .nav-container--back-container {
    padding: 80px 60px;
  }
}

.nav-container .nav-container--back-container .nav-container--title__back {
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 1.8em;
  margin-bottom: 0;
  font-weight: 100;
}

.nav-container .nav-container--back-container .nav-container--description {
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 1em;
  font-weight: 500;
}

.nav-container .nav-container--back-container .nav-container--link {
  border: #fff 1px solid;
  padding: 2px 5px 2px 5px;
  color: #fff;
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nav-container__cyan {
  background-color: #00aeef;
}

.nav-container__cyan:hover {
  background-color: #e5b53b;
}

.dd-banner {
  background-color: #50c8e8;
  width: 100%;
}

.dd-banner .dd-banner--title {
  color: #fff;
  font-size: 3.45em;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .dd-banner .dd-banner--title {
    padding: 30px 0;
  }
}

.dd-banner .dd-banner--content {
  color: #fff;
  font-size: 1.15em;
}

@media (min-width: 768px) {
  .dd-banner .dd-banner--content {
    padding: 30px 0;
    margin-bottom: 0;
  }
}

.dd-banner--selector-bar {
  background-color: #46aecb;
  width: 100%;
  height: 20px;
  cursor: pointer;
}

.dd-banner--selector-bar span {
  display: none;
}

@media (min-width: 768px) {
  .dd-banner--selector-bar span {
    color: #fff;
    background-color: #46aecb;
    line-height: 20px;
    right: 30px;
    font-size: 1.15em;
    position: absolute;
    display: inline;
    text-transform: uppercase;
  }
}

.dd-banner--selector-bar:after {
  content: '+';
  background-color: #222;
  color: #fff;
  line-height: 0;
  font-size: 1.5em;
  height: 20px;
  padding: 10px 3px 0 4px;
  position: absolute;
  right: 45%;
}

@media (min-width: 768px) {
  .dd-banner--selector-bar:after {
    right: 0;
  }
}

.dd-banner--selector-bar.open:after {
  content: '-';
  background-color: #222;
  color: #fff;
  line-height: 0;
  font-size: 2.2em;
  height: 20px;
  padding: 9px 5px 0 6px;
  position: absolute;
  right: 45%;
}

@media (min-width: 768px) {
  .dd-banner--selector-bar.open:after {
    right: 0;
  }
}

.dd-content {
  display: none;
  background-color: #dedede;
}

.dd-content .dd-content--content {
  padding: 5px 8px 20px 8px;
  position: relative;
  font-size: .8em;
}

.dd-content .dd-content--content p {
  font-size: 14px;
}

.dd-content .dd-content--content p strong {
  font-size: 16px;
}

.dd-content .dd-content--content.no-pad {
  padding: 0;
}

.dd-content .dd-content--content.dd-content--content__fade:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(222, 222, 222, 0)), color-stop(40%, rgba(222, 222, 222, 0)), color-stop(70%, #dedede));
  background: linear-gradient(to bottom, rgba(222, 222, 222, 0) 0, rgba(222, 222, 222, 0) 40%, #dedede 70%);
  z-index: 1;
  bottom: 10px;
}

.dd-content .dd-content--content .dd-content--image-link .dd-content--image-button {
  width: 100%;
  top: 45%;
  position: absolute;
  text-align: center;
  margin: 0 auto;
}

.dd-content .dd-content--content .dd-content--image-link .dd-content--image-button span {
  color: #fff;
  border: 1px #fff solid;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.dd-content .dd-content--content .dd-content--image-link--image {
  display: block;
  width: 100%;
}

.dd-content .dd-content--button {
  border: 2px #222 solid;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
}

.dd-content .dd-content--button-cont {
  position: relative;
  z-index: 5;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  bottom: 0;
  display: block;
}

.dd-content .dd-content--selector-bar {
  width: 100%;
  height: 30px;
}

.dd-content .dd-content--selector-bar .dd-content--header {
  padding: 3px 0 0 10px;
  font-size: 18px;
  font-weight: 700;
}

.dd-content__grey .dd-content--selector-bar {
  background-color: #c1c1c1;
}

.dd-content__red {
  background-color: #c1c1c1;
}

.dd-content__red .dd-content--selector-bar {
  background-color: #bf311b;
}

.dd-content__red .dd-content--selector-bar .dd-content--header {
  color: #fff;
}

.dd-content__red .dd-content--content.dd-content--content__fade:before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(193, 193, 193, 0)), color-stop(40%, rgba(193, 193, 193, 0)), color-stop(70%, #c1c1c1));
  background: linear-gradient(to bottom, rgba(193, 193, 193, 0) 0, rgba(193, 193, 193, 0) 40%, #c1c1c1 70%);
}

.dd-content__green {
  background-color: #cce9e9;
}

.dd-content__green .dd-content--selector-bar {
  background-color: #00928f;
}

.dd-content__green .dd-content--selector-bar .dd-content--header {
  color: #fff;
}

.dd-content__green .dd-content--content.dd-content--content__fade:before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(204, 233, 233, 0)), color-stop(40%, rgba(204, 233, 233, 0)), color-stop(70%, #cce9e9));
  background: linear-gradient(to bottom, rgba(204, 233, 233, 0) 0, rgba(204, 233, 233, 0) 40%, #cce9e9 70%);
}

.vp-background__black {
  background-color: #000;
  padding: 30px 0;
}

@media (min-width: 768px) {
  .vp-background__black iframe {
    height: 480px;
  }
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.owl-carousel .owl-stage {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-stage-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
  direction: ltr;
}

.owl-carousel .owl-stage-outer.autoHeight {
  -webkit-transition: height .5s ease-in-out;
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item {
  float: left;
}

.owl-controls .owl-buttons div,
.owl-controls .owl-page {
  cursor: pointer;
}

.owl-controls {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-item,
.owl-carousel .owl-stage {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media print {

  #comments,
  .corp-links,
  .js-nav-toggle,
  .nav-primary,
  .nav-sector-toggle,
  .nav-tertiary,
  .nav-tertiary-collapse,
  .news-meta-share,
  .searchBox,
  .site-footer,
  .site-meta,
  .smart-search-input,
  .upper-elements {
    display: none !important;
  }

  .page-content {
    padding-top: 0 !important;
    margin-top: -20px !important;
  }

  .container {
    width: 100% !important;
    padding-right: 20px !important;
  }
}

img.cke_youtube {
  width: 100% !important;
  height: auto !important;
}

body {
  font-family: Montserrat, Arial, Helvetica, sans-serif !important;
}

.page-content h1 {
  color: #00aeef;
  text-transform: uppercase;
}

.page-content h3 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.page-content h4 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.page-content h2 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

@media (min-width: 929px) {
  .site-identity {
    color: #000;
    font-size: 1.5em;
    font-weight: 200;
    margin-top: 0.75em;
  }

  .site-logo {
    margin-right: 0;
  }
}

.nav-primary li>a {
  color: #000;
}

.nav-primary {
  background-color: #fff;
}

.site-logo img {
  width: 200px;
}

@media (min-width: 767px) {

  .site-meta .languageSelection,
  .site-meta .social {
    top: 9px;
  }
}

.nav-primary li.Highlighted>a:after {
  display: none;
}

.nav-primary li.Highlighted>a {
  background-color: #004c6c;
  color: #fff !important;
}

.breadcrumb a:after {
  content: "/";
  color: #000;
  position: absolute;
  right: -15px;
}

.breadcrumb a {
  color: #485daa;
}

.breadcrumb span {
  color: #000;
}

.breadcrumb .container:after {
  border-bottom: 1px solid #ccc;
  margin-top: 15px;
}

.strip {
  background-color: #fff;
}

.quote-body,
blockquote {
  font-size: 19px;
  padding: 0;
  margin-bottom: 1em;
  line-height: 1.3;
  background-color: #fff;
  color: #00aeef;
  font-weight: 500;
}

.quote-body:before {
  content: "\"";
  margin-right: -4px;
}

.quote-body:after {
  content: "\"";
  margin-left: -4px;
}

.quote-citation {
  left: 0;
  font-size: 18px;
}

blockquote:after {
  display: none;
}

.quote-citation {
  color: #000;
  font-weight: 600;
}

.landing-strips .landing-strip {
  background-color: #fff;
}

.nav-tertiary li.Highlighted a:first-child {
  color: #000;
}

.nav-tertiary li>a {
  border-left: none;
  font-size: 1em;
  line-height: 1.5;
  border-bottom: 1px solid #ccc;
  color: #485daa;
}

.nav-tertiary li.Highlighted ul a {
  color: #485daa !important;
}

@media (min-width: 767px) {
  .landing-strip .nav-tertiary li>a {
    border-left: none;
  }
}

@media (min-width: 767px) {
  .banner-title {
    font-size: 2em;
    text-transform: uppercase;
  }
}

.banner-title {
  font-weight: 500;
}

@media (min-width: 767px) {
  .banner-description {
    font-size: 1.2em;
    font-weight: 500;
  }
}

.nav-tertiary li>a {
  display: block;
  padding: .6em 0 .4em 0;
}

.s-result.s-result-person-lean {
  border-bottom: none !important;
  border-top: 1px solid #ccc;
}

.s-result-person-lean .pair {
  float: none;
}

.s-result-person-lean .s-result-sub2-title {
  float: none;
  padding: 15px 0;
}

h3.s-result-sub2-title {
  margin-bottom: 0;
}

.s-result {
  background-color: #fff;
  margin-bottom: 0;
}

.s-result-inner {
  padding: 0;
}

@media (min-width: 480px) {
  .s-result-person {
    min-height: 165px;
  }
}

.s-result-person-lean .s-result-sub2-title .fullName {
  color: #485daa;
  text-transform: uppercase;
  font-weight: 400;
}

.s-result-title a {
  color: #00aeef;
  text-transform: uppercase;
}

.s-result-sub-title {
  color: #000;
  margin-bottom: 10px;
}

.s-result-sub2-title {
  color: #000;
}

.pair a.pair-item {
  color: #21607a;
}

.s-result.s-result-person-lean {
  border-bottom: 1px solid #ccc;
}

.btn-altCol {
  background-color: #21607a;
}

.s-result-person-lean .pair-tel .btn {
  background-color: #fff;
  border: 1px solid #00aeef;
  color: #00aeef;
  padding: .6rem 1rem;
}

.s-result-person-lean .pair-tel .btn:hover {
  background-color: #00aeef;
  border: 1px solid #00aeef;
  color: #fff;
}

.btn-arrow-down:after {
  display: none;
}

.directory-people .btn-arrow-down {
  margin-top: 1.5em;
  text-transform: uppercase;
  font-weight: 600;
}

.directory-people .btn-arrow-down:hover {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #00aeef;
}

.btn-arrow-down,
.btn-share {
  padding-right: .6em;
}

.directory-form .col-centered {
  text-align: left;
}

.col-centered {
  margin: 0;
}

.directory-form {
  padding: 0;
  background-color: transparent;
}

.btn {
  text-shadow: none;
}

.contact-card {
  padding-bottom: 20px;
}

.block-head--icon:after {
  display: none;
}

.btn-arrow-down:after,
.btn-arrow:after,
.btn-share:after {
  display: none;
}

.btn-arrow {
  padding-right: .8em;
}

.contact-piece.contact-btn {
  text-align: center;
}

.btn-secondary {
  background-color: #004c6c;
}

.s-result-location .pair:last-of-type {
  margin-bottom: 30px;
}

.person-bio {
  display: table-header-group;
}

.island {
  background-color: #fff;
  padding: 0;
  margin-bottom: 0;
}

.island h3 {
  margin-top: 40px;
  padding-top: 10px;
  font-size: 20px;
  color: #00aeef;
  border-top: 1px solid #00aeef;
  text-transform: uppercase;
  font-weight: 500;
}

.list--plain {
  line-height: 2.2em;
}

.peopleList .pagination {
  float: right;
  margin-top: 60px;
}

.search-results-people {
  margin-top: 60px;
}

.pagination li {
  background-color: #999;
}

.pagination li:hover {
  background-color: #ccc;
}

.pagination li a:hover {
  color: #000;
}

.pagination li.is-current {
  background-color: #ccc;
  color: #000;
}

.icon-arrow-left:before {
  content: " ";
  width: 0;
  height: 0;
  display: inline-block;
  border-style: solid;
  border-width: .5em 0 .5em .5em;
  border-color: transparent transparent transparent #fff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 10px;
}

.icon-arrow-right:before {
  content: " ";
  width: 0;
  height: 0;
  display: inline-block;
  border-style: solid;
  border-width: .5em 0 .5em .5em;
  border-color: transparent transparent transparent #fff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 10px;
}

.icon-arrow-left {
  margin: -15px;
}

.s-result {
  border-bottom: none;
  border-top: 1px solid #00aeef;
  padding-top: 15px;
}

.s-result-title a {
  font-weight: 500;
  font-size: 20px;
}

.s-result-sub-title a {
  color: #000;
}

.input--text,
.select,
input[type=text],
input[type=file],
td.EditingFormValueCell select,
td.form-table-value-cell select,
textarea {
  border-bottom: none;
}

.news-listing-body {
  border-bottom: none;
}

.landing-sector-description ul>li,
.ms-wysiwyg ul>li,
.msEventWidget ul>li,
.news-post ul>li,
.person-bio ul>li,
.sitemap ul>li,
.vacancy-description ul>li,
.widget-content ul>li {
  list-style-type: unset;
}

.nav-primary-buttons .nav-primary-buttons-wrapper {
  margin-top: 5px;
}

.nav-primary-buttons .menu-link {
  float: left;
  padding: 4px 3px;
  background: #50c8e8;
  color: #fff;
  border-radius: 0;
  margin: 5px 5px 5px 15px;
}

.owl-pagination .owl-page {
  border-radius: 0;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .banner-title {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 1.2em;
  }

  .banner-description {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  .banner-container .btn,
  .banner-container .btn-arrow,
  .banner-container .btn-arrow-down,
  .banner-container .btn-banner,
  .banner-container .btn-share {
    font-size: 12px !important;
  }

  .btn-arrow:after {
    display: none !important;
  }

  .banner-container .btn-arrow {
    padding: 0.5rem 1rem !important;
  }

  .banner-content-text {
    position: absolute;
    bottom: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.nav-primary li>a {
  color: #fff;
}

@media (max-width: 992px) {
  .menu-panel {
    border-top: none;
  }
}

.nav-primary {
  background-color: #f5f5f5;
}

.footer-branding img {
  width: 70%;
}

.footer-branding img:nth-child(2) {
  display: none;
}

@media (min-width: 992px) {
  .copyright {
    top: 15px;
  }
}

.landing-strip h1 {
  color: #00aeef;
}

.landing-sector-description ul {
  list-style: unset;
}

.s-result-content-primary a.btn-people-email {
  background-color: #fff;
  color: #21607a !important;
  border: none;
  padding: 0 0 0 5px;
  text-transform: unset;
}

.s-result-content-primary a.btn-people-email:before {
  content: "\/ ";
  padding-right: 5px;
}

.s-result-content-primary a.btn-people-email:after {
  background-color: #fff;
}

.s-result-content-primary a.btn.btn-people-email:hover {
  color: #00aeef !important;
  background-color: #fff;
}

.pair-title {
  color: #000;
  font-weight: 400;
}

.s-result-sub-title,
.s-result-sub2-title {
  font-size: .9em;
  color: #000;
}

.pair .pair-tel {
  font-size: .9rem;
}

@media (min-width: 992px) {
  .s-result-otherLocs {
    display: inline-block;
  }

  .s-result-otherLocs .s-result-hoverBtn {
    border-bottom: none;
    color: #21607a;
  }
}

.pair {
  margin-bottom: 0;
}

.s-result-title {
  margin-bottom: .4em;
}

.widget-title.has-icon:before {
  display: none;
}

.widget-title.has-icon {
  padding-left: 0;
}

.people-profile-header__profile h2 {
  font-size: 1.2rem;
  font-weight: 500 !important;
}

.person-contact-detail-vcard::before {
  display: none;
}

.person-contact-detail-vcard a {
  color: #485daa;
}

.person-contact-detail-vcard a:hover {
  color: #00aeef;
}

.s-result-content-primary a:hover {
  color: #00aeef;
}

.block-head {
  padding: 10px 0 0 0;
  border-top: 1px solid #00aeef;
  background-color: #fff;
  color: #00aeef;
}

.block-title {
  color: #00aeef;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
}

.block-body {
  padding: 0;
  background-color: #fff;
}

.contact-card {
  padding: 20px 0 0 0;
  margin: 0;
  border-bottom: none;
}

.contact-title {
  font-weight: 600;
}

.contact-piece.contact-btn {
  text-align: left;
}

.contact-piece.contact-btn a {
  color: #485daa;
  border: none;
  padding: 0;
  text-transform: unset;
}

.contact-piece.contact-btn a:hover {
  background-color: #fff;
  color: #00aeef;
}

.contact-piece {
  margin-bottom: .8rem;
}

.ms-wysiwyg blockquote {
  font-size: 19px;
  font-weight: 500;
  padding: 0;
}

.ms-wysiwyg blockquote p:before {
  content: "\"";
}

.ms-wysiwyg blockquote p:after {
  content: "\"";
}

.ms-wysiwyg h5 {
  font-weight: 600;
  color: #000;
  font-size: 18px;
}

.form-light .input--text,
.form-light .select,
.form-light input[type=text],
.form-light input[type=file],
.form-light td.EditingFormValueCell select,
.form-light td.form-table-value-cell select,
.form-light textarea,
td.EditingFormValueCell .form-light select,
td.form-table-value-cell .form-light select {
  background-color: #fff;
  border: 1px solid #ccc;
}

.landing-strips .landing-strip:nth-child(1) h1 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
}

[data-icon]:before {
  font-family: additional-icons !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class*=" msa-icon-"]:before,
[class^=msa-icon-]:before {
  font-family: additional-icons;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.msa-icon-icon-location:before {
  content: "a";
}

.msa-icon-icon-member:before {
  content: "b";
}

.msa-icon-icon-search:before {
  content: "c";
}

.nav-button:before {
  content: "b";
  font-family: additional-icons;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  left: 0;
  color: #00aeef;
  font-size: 2em;
}

.icon-search:before,
.input-icon-search .js-btn-append:after,
.nav-search:before,
.smart-search-input .js-btn-append:after {
  content: "c";
}

.js-btn-append:after {
  content: "b";
  font-family: additional-icons !important;
  color: #00aeef !important;
}

.footer-branding a.location-pin:before {
  content: "a";
  font-family: additional-icons;
  margin-right: 10px;
  font-size: 2em;
  vertical-align: middle;
}

.site-footer a:hover {
  text-decoration: none;
}

.widget-cyan .widget-content h2,
.widget-cyan .widget-description,
.widget-cyan .widget-description p p p,
.widget-cyan .widget-sub-date,
.widget-cyan .widget-sub-title2,
.widget-cyan .widget-title {
  color: #000;
}

.widget-pastel-blue .widget-content h2,
.widget-pastel-blue .widget-description,
.widget-pastel-blue .widget-description p,
.widget-pastel-blue .widget-sub-date,
.widget-pastel-blue .widget-sub-title2,
.widget-pastel-blue .widget-title {
  color: #fff;
}

.widget-light-blue h3 {
  color: #fff;
}

.widget-light-blue a {
  color: #fff;
}

.widget-pastel-green h3 {
  color: #fff;
}

.widget-pastel-green a {
  color: #fff;
}

.widget-cyan h3 {
  color: #fff;
}

.widget-cyan h2 {
  color: #fff;
}

.widget-secondary-blue {
  padding: 2em !important;
}

.page-content.medium {
  background-color: #f5f5f5;
}

.icon-arrow-left-double:before,
.icon-arrow-right-double:before,
.icon-rewind:before {
  font-family: icon-font;
  content: "";
  margin: 10px;
}

.pagination-next,
.pagination-prev {
  padding-top: 2px;
}

.s-result-person-lean .s-result-sub2-title {
  float: left;
}

.s-result-person-lean .pair {
  float: right;
}

.s-result-person-lean h3 {
  font-size: 16px;
}

.s-result.s-result-person-lean {
  min-height: 80px;
}

.s-result.s-result-person-lean .pair-tel .btn {
  padding: .2em;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .s-result-person-lean .pair {
    float: none;
    margin-top: -.1em;
  }

  .s-result-sub2-title {
    width: 100%;
  }

  .s-result-person-lean .s-result-sub2-title {
    padding: 0;
  }

  .s-result.s-result-person-lean {
    padding: 15px 0;
  }

  .peopleList .pagination {
    float: none;
  }
}

.owl-stage .banner-container {
  position: relative;
}

.owl-stage .banner-container:before {
  content: " ";
  background-image: url(/dist/img/branding/header-cover.svg);
  background-size: cover;
  background-position: center 1.5em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media all and (max-width: 768px) {
  .owl-stage .banner-container:before {
    background-position-y: 50%;
  }
}

.banner-container .btn,
.banner-container .btn-arrow,
.banner-container .btn-arrow-down,
.banner-container .btn-banner,
.banner-container .btn-share {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  padding: .6rem 1rem;
  text-align: center;
  -webkit-transition: all .25s;
  transition: all .25s;
  border-radius: 0;
  position: relative;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  text-shadow: none;
}

.banner-container .btn-arrow {
  padding-right: 1rem;
}

.banner-container .btn-arrow-down:hover,
.banner-container .btn-arrow:hover,
.banner-container .btn-banner:hover,
.banner-container .btn-share:hover,
.banner-container .btn:hover {
  background-color: #00aeef;
  border-color: #00aeef;
}

.banner-container {
  position: relative;
  overflow: hidden;
}

.banner-container .banner-content-text {
  text-shadow: none;
}

.banner-container:before {
  content: " ";
  background-image: url(/dist/img/branding/inner-header-cover.svg);
  background-size: cover;
  background-position-y: calc(50% + 30px);
  background-position-x: calc((100vw/3)*-1);
  background-repeat: no-repeat;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 0;
}

@media all and (max-width: 992px) {
  .banner-container:before {
    background-position-y: 50%;
  }
}

.page-content.darker {
  background-color: #e5e5e5;
}

.site-header .social-link {
  width: 24px;
  height: 24px;
  font-size: 14px;
}

.site-header .social-link:hover {
  background-color: #989898;
}

.site-header .social-link:before {
  font-size: 14px;
  top: 5px;
  left: 5px;
}

.site-header .social-link.social-link-twitter:before {
  top: 5.6px;
}

.nav-secondary ul li {
  text-transform: uppercase;
}

.nav-secondary ul li {
  font-size: 14px;
  font-weight: 500;
}

.nav-secondary ul li a {
  color: #000;
}

.nav-secondary ul li a:hover {
  color: #000;
}

.nav-button {
  background-color: #fff;
}

.nav-button:hover {
  background-color: #fff;
}

@media (min-width: 1200px) {
  .nav-primary li ul {
    width: 100%;
  }
}

@media (min-width: 992px) {
  #NavPrimary>li:last-child>a {
    margin-right: 0;
  }

  #NavPrimary>li>a {
    color: #000;
    padding-left: 1em;
    padding-right: 1em;
    margin-right: 0;
    font-weight: 500;
  }

  #NavPrimary>li:hover>a {
    background-color: #004c6c;
    color: #fff;
  }

  #NavPrimary li ul {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    left: 0;
    padding: 1em;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #004c6c;
    border-radius: 0;
  }

  #NavPrimary li ul>li {
    float: none;
    width: 100%;
  }

  #NavPrimary li ul>li a {
    background-color: transparent;
    color: #fff;
    border-bottom: 1px solid transparent;
    font-weight: 300;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
  }

  #NavPrimary li.Highlighted ul>li.Highlighted a {
    border-bottom-color: #00aeef;
    color: #00aeef;
  }

  #NavPrimary li ul>li a:hover {
    border-bottom-color: #00aeef;
    color: #00aeef;
  }
}

.page-content .widget.panel {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0 !important;
}

.page-content .widget.panel .widget-title {
  font-weight: 500;
}

.page-content .widget.panel .widget-sub-title {
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
}

.page-content .panel .widget-image {
  margin-bottom: 0;
}

.page-content .panel .widget-image img {
  width: 100%;
}

.page-content .panel .widget-image a {
  display: block;
  overflow: hidden;
}

@media (min-width: 767px) {
  .page-content .widgets-wide .widget-wrapper .widget-image {
    width: 22.5em;
  }
}

.page-content .panel .widget-content {
  padding: 1.5em;
}

.page-content .panel .widget-link {
  border-top: 0;
}

.site-sidebar .widget.panel {
  padding: 2em !important;
}

.site-sidebar .widget.panel .widget-content {
  padding: 0;
}

.widget-light-grey {
  background-color: #f5f5f5;
}

.widget-bold-green {
  background-color: #16b685;
}

.widget-bold-blue {
  background-color: #485daa;
}

.widget-secondary-blue {
  background-color: #004c6c;
}

.widget-light-blue {
  background-color: #00aeef;
}

.widget-pastel-green {
  background-color: #a8ad2c;
}

.widget-light-blue .widget-content h2,
.widget-light-blue .widget-description,
.widget-light-blue .widget-description p p p,
.widget-light-blue .widget-links,
.widget-light-blue .widget-sub-date,
.widget-light-blue .widget-sub-title2,
.widget-light-blue .widget-title {
  color: #fff;
}

.widget-pastel-green .widget-content h2,
.widget-pastel-green .widget-description,
.widget-pastel-green .widget-description p p p,
.widget-pastel-green .widget-links,
.widget-pastel-green .widget-sub-date,
.widget-pastel-green .widget-sub-title2,
.widget-pastel-green .widget-title {
  color: #fff;
}

.widget.panel.widget-bold-blue .widget-sub-title,
.widget.panel.widget-bold-green .widget-sub-title,
.widget.panel.widget-pastel-green .widget-sub-title,
.widget.panel.widget-secondary-blue .widget-sub-title {
  color: #fff;
}

.panel .widget-link .btn-arrow-down:hover,
.panel .widget-link .btn-arrow:hover,
.panel .widget-link .btn-share:hover,
.panel .widget-link .btn:hover {
  background-color: #33a8a5;
  color: #fff;
}

.panel .widget-link .btn,
.panel .widget-link .btn-arrow,
.panel .widget-link .btn-arrow-down,
.panel .widget-link .btn-share {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  padding: .6rem 1rem;
  text-align: center;
  -webkit-transition: all .25s;
  transition: all .25s;
  border-radius: 0;
  position: relative;
  background-color: transparent;
  color: #00aeef;
  border: 1px solid #00aeef;
  text-shadow: none;
}

.panel .widget-link .btn-arrow-down:hover,
.panel .widget-link .btn-arrow:hover,
.panel .widget-link .btn-share:hover,
.panel .widget-link .btn:hover {
  background-color: #00aeef;
  border: 1px solid #00aeef;
  color: #fff;
}

.panel.widget-bold-green .widget-link .btn,
.panel.widget-bold-green .widget-link .btn-arrow,
.panel.widget-bold-green .widget-link .btn-arrow-down,
.panel.widget-bold-green .widget-link .btn-share {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.panel.widget-bold-green .widget-link .btn-arrow-down:hover,
.panel.widget-bold-green .widget-link .btn-arrow:hover,
.panel.widget-bold-green .widget-link .btn-share:hover,
.panel.widget-bold-green .widget-link .btn:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #00aeef;
}

.panel.widget-bold-green .widget-link .btn,
.panel.widget-bold-green .widget-link .btn-arrow,
.panel.widget-bold-green .widget-link .btn-arrow-down,
.panel.widget-bold-green .widget-link .btn-share {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.panel.widget-secondary-blue .widget-link .btn,
.panel.widget-secondary-blue .widget-link .btn-arrow,
.panel.widget-secondary-blue .widget-link .btn-arrow-down,
.panel.widget-secondary-blue .widget-link .btn-share {
  border: 1px solid #fff;
  color: #fff;
}

.panel.widget-secondary-blue .widget-link .btn-arrow-down:hover,
.panel.widget-secondary-blue .widget-link .btn-arrow:hover,
.panel.widget-secondary-blue .widget-link .btn-share:hover,
.panel.widget-secondary-blue .widget-link .btn:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #00aeef;
}

.panel.widget-bold-blue .widget-link .btn,
.panel.widget-bold-blue .widget-link .btn-arrow,
.panel.widget-bold-blue .widget-link .btn-arrow-down,
.panel.widget-bold-blue .widget-link .btn-share {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.panel.widget-bold-blue .widget-link .btn-arrow-down:hover,
.panel.widget-bold-blue .widget-link .btn-arrow:hover,
.panel.widget-bold-blue .widget-link .btn-share:hover,
.panel.widget-bold-blue .widget-link .btn:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #00aeef;
}

.panel.widget-pastel-green .widget-link .btn,
.panel.widget-pastel-green .widget-link .btn-arrow,
.panel.widget-pastel-green .widget-link .btn-arrow-down,
.panel.widget-pastel-green .widget-link .btn-share {
  border: 1px solid #fff;
  color: #fff;
}

.panel.widget-pastel-green .widget-link .btn-arrow-down:hover,
.panel.widget-pastel-green .widget-link .btn-arrow:hover,
.panel.widget-pastel-green .widget-link .btn-share:hover,
.panel.widget-pastel-green .widget-link .btn:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #00aeef;
}

.panel.widget-pastel-blue .widget-link .btn,
.panel.widget-pastel-blue .widget-link .btn-arrow,
.panel.widget-pastel-blue .widget-link .btn-arrow-down,
.panel.widget-pastel-blue .widget-link .btn-share {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.panel.widget-pastel-blue .widget-link .btn-arrow-down:hover,
.panel.widget-pastel-blue .widget-link .btn-arrow:hover,
.panel.widget-pastel-blue .widget-link .btn-share:hover,
.panel.widget-pastel-blue .widget-link .btn:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #00aeef;
}

.panel.widget-light-blue .widget-link .btn,
.panel.widget-light-blue .widget-link .btn-arrow,
.panel.widget-light-blue .widget-link .btn-arrow-down,
.panel.widget-light-blue .widget-link .btn-share {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.panel.widget-light-blue .widget-link .btn-arrow-down:hover,
.panel.widget-light-blue .widget-link .btn-arrow:hover,
.panel.widget-light-blue .widget-link .btn-share:hover,
.panel.widget-light-blue .widget-link .btn:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #00aeef;
}

.panel.widget-light-grey .widget-link .btn,
.panel.widget-light-grey .widget-link .btn-arrow,
.panel.widget-light-grey .widget-link .btn-arrow-down,
.panel.widget-light-grey .widget-link .btn-share {
  border-color: #00aeef;
  color: #00aeef;
}

.panel.widget-light-grey .widget-link .btn-arrow-down:hover,
.panel.widget-light-grey .widget-link .btn-arrow:hover,
.panel.widget-light-grey .widget-link .btn-share:hover,
.panel.widget-light-grey .widget-link .btn:hover {
  background-color: #00aeef;
  border: 1px solid #00aeef;
  color: #fff;
}

.widget-title.has-icon:before {
  border-radius: 0;
}

.site-footer {
  border-top: 1px solid #00aeef;
  background-image: none;
  background-color: #fff;
}

.corp-links {
  padding-bottom: 2em;
}

@media (min-width: 992px) {
  .corp-links .social {
    text-align: right;
  }
}

.copyright,
.copyright a {
  font-size: 14px;
}

.people-profile-header {
  background-color: #eee;
  position: relative;
}

.people-profile-header__container {
  height: 360px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.people-profile-header__profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.people-profile-header__profile .person-contact-details {
  margin-bottom: 0;
}

.people-profile-header__profile h1 {
  text-transform: uppercase;
  font-weight: 600;
  color: #00aeef;
}

.people-profile-header__profile h2 {
  color: #000;
  font-weight: 700;
}

.people-profile-header .person-profile-photo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media all and (max-width: 992px) {
  .people-profile-header__container {
    height: 300px;
  }

  .people-profile-header__container.profile-photo {
    min-height: auto;
    height: auto;
  }
}

.people-profile-header .person-profile-photo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.people-profile-header__profile .person-contact-details .person-contact-detail {
  font-size: 1.1em;
}

.people-profile-header__profile .person-contact-details .person-contact-detail a.person-contact-detail-info {
  color: #485daa;
}

.people-profile-header__profile .person-contact-details .person-contact-detail a.person-contact-detail-info:active,
.people-profile-header__profile .person-contact-details .person-contact-detail a.person-contact-detail-info:focus,
.people-profile-header__profile .person-contact-details .person-contact-detail a.person-contact-detail-info:hover {
  color: #00aeef;
}

.people-profile-header__profile .person-contact-details .person-contact-detail.telno a.person-contact-detail-info:before {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  content: "T";
  margin-right: 10px;
  color: #000;
}

@media all and (min-width: 992px) {
  .people-profile-header__profile .person-contact-details .person-contact-detail {
    display: inline-block;
  }

  .people-profile-header__profile .person-contact-details .person-contact-detail.person-contact-detail-email a.person-contact-detail-info:before {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    content: "/";
    margin-right: 10px;
    margin-left: 10px;
    color: #000;
  }

  .people-profile-header__profile .person-contact-details .person-contact-detail.person-contact-detail-vcard a:after {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    content: "/";
    margin-right: 10px;
    margin-left: 10px;
    color: #000;
    float: left;
  }
}

@media all and (max-width: 768px) {
  .people-profile-header__profile {
    display: block;
    padding: 2em 0;
    height: auto;
  }

  .people-profile-header__profile h1,
  .people-profile-header__profile h2 {
    margin-bottom: 1em;
  }

  .people-profile-header .person-profile-photo {
    display: block;
    height: auto;
    padding: 0 0 2em 0;
  }

  .people-profile-header .person-profile-photo img {
    width: auto;
  }

  .people-profile-header__profile .person-contact-details .person-contact-detail {
    display: block;
  }

  .people-profile-header__profile .person-contact-details .person-contact-detail.person-contact-detail-email a.person-contact-detail-info:before {
    display: none;
  }

  .person-profile-primary .person-bio {
    margin-bottom: 2em;
    display: block;
  }
}

.page-content .btn,
.page-content .btn-arrow,
.page-content .btn-arrow-down,
.page-content .btn-share {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  padding: .6rem 1rem;
  text-align: center;
  -webkit-transition: all .25s;
  transition: all .25s;
  border-radius: 0;
  position: relative;
  background-color: transparent;
  color: #00aeef;
  border: 1px solid #00aeef;
  text-shadow: none;
}

.page-content .btn-arrow-down:hover,
.page-content .btn-arrow:hover,
.page-content .btn-share:hover,
.page-content .btn:hover {
  background-color: #00aeef;
  border-color: #00aeef;
  color: #fff;
}

.news-listing.panel {
  border-radius: 0;
  background-color: #f5f5f5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.generic-search-result .panel {
  border-radius: 0;
  background-color: #f5f5f5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.generic-search-result .panel h2 {
  font-weight: 500;
}

.generic-search-result .panel a {
  color: #00aeef;
}

.generic-search-result .panel a:hover {
  color: #485daa;
}

.s-result-content-primary a.btn.btn-small {
  color: #00aeef;
}

a.btn.btn-small {
  color: #00aeef;
}

a.btn.btn-small:hover {
  background-color: #00aeef;
  color: #fff;
}

.s-result {
  border-radius: 0;
}

.search-results-people table td {
  border: 0 !important;
}

.site-meta {
  margin-top: 15px;
}

.contact-piece .btn-arrow:after {
  display: none !important;
}

.news-meta-share a {
  color: #00aeef !important;
}

.news-meta-share a:hover {
  color: #fff !important;
}

.tags-meta .tag {
  background-color: #16b685;
}

.tags-meta .tag:hover {
  background-color: #16b685;
}

.tag {
  text-shadow: none;
}

.widget-pastel-green .widget-title {
  border-color: #fff;
}

.widget-light-blue {
  color: #fff;
}

.widget-cyan {
  color: #fff;
}

.widget-contact-details h4,
h3 {
  color: #fff;
}

.widget-pastel-green a:hover {
  color: #fff;
}

.widget-cyan .widget-title {
  color: #fff;
}

.widget-pastel-blue .widget-sub-title {
  color: #fff !important;
}

.widget-light-blue .widget-sub-title {
  color: #fff !important;
}

.widget-pastel-green {
  color: #fff !important;
}

.mstphAccordionTileTitle span.widget-title {
  color: #fff;
}

.mstphAccordionTileTitle:after {
  color: #fff;
}

.widget-light-blue li a {
  color: #fff;
}

.ms-wysiwyg h3 {
  color: #000 !important;
}

.sitemap ul {
  list-style-type: disc;
}

.ms-wysiwyg ul {
  list-style-type: disc;
}

.widget-links ul {
  list-style-type: disc;
}

.icon-clock:before,
.news-meta-date:before {
  content: "";
  font-family: icon-font;
}

.block--location .block-head:after,
.icon-location:before {
  content: "";
  font-family: icon-font;
}

.msEventWidget .widget-image {
  margin-bottom: 15px !important;
}

.vacancy-details .island {
  padding: 30px;
}

.widgets-wide {
  display: block;
}

.widgets-wide .widget-image>a {
  display: block;
  height: 1px;
  width: 22.5em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media all and (max-width: 767px) {
  .widgets-wide .widget-image>a {
    width: 100%;
    height: 250px !important;
  }
}

.widgets-wide .widget-image>a img {
  display: none;
}

.widget-contact .widget-contact-img {
  float: none;
}

.widget-contact .widget-contact-details .widget-contact-role {
  color: #000;
}

.ConsentButtons {
  margin-left: 10px;
}

.ConsentButtons input {
  border: 1px solid #fff;
  background-color: transparent;
}

.ConsentButtons input:hover {
  background-color: #fff;
  color: #26a;
}

.person-profile-photo img {
  max-height: 360px;
}

.search-result-data-department ul {
  list-style: disc;
}

.news-post-body ul {
  list-style: disc;
}

/* Custom additions follow */
.vacancy-list-link {
  line-height: 1;
  color: #5c5c5c;
  outline: 0px;
}
