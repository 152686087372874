
@font-face {
  font-family: "Goudy Old Style";
  font-style: normal;
  font-weight: 400;
  src: local("Goudy Old Style"), url("/dist/fonts/goudy-old-style/GOUDOS.TTF") format("truetype");
}

@font-face {
  font-family: "Goudy Old Style";
  font-style: normal;
  font-weight: 700;
  src: local("Goudy Old Style"), url("/dist/fonts/goudy-old-style/GOUDOSB.TTF") format("truetype");
}

@font-face {
  font-family: "Goudy Old Style";
  font-style: italic;
  font-weight: 400;
  src: local("Goudy Old Style"), url("/dist/fonts/goudy-old-style/GOUDOSI.TTF") format("truetype");
}
